@charset "UTF-8";
/* -------------------------------------------------------------------------- */
/*                                   Fonts                                    */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                                  Couleurs                                  */
/* -------------------------------------------------------------------------- */
/*------------------------------------------------
:: BREAKPOINTS
--------------------------------------------------*/
/*------------------------------------------------
:: FONT SIZE
--------------------------------------------------*/
/* -------------------------------------------------------------------------- */
/*                                   GLOBAL                                   */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                                   Marges                                   */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                                   SHADOWS                                  */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                         Variables de configuration                         */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                         Surcharge Bootstrap / ssks                         */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                                 breadcrumbs                                */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                                    input                                   */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                                     hr                                     */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                                     TAB                                    */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                                   BUTTONS                                  */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                                    TABS                                    */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                                  DROPDOWNS                                 */
/* -------------------------------------------------------------------------- */
:root {
  --bs-blue: #3f69b2;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #fc3f59;
  --bs-orange: #ff9761;
  --bs-yellow: #ffee00;
  --bs-green: #53b166;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #999999;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #121212;
  --bs-primary: #121212;
  --bs-secondary: #6c757d;
  --bs-success: #53b166;
  --bs-info: #3f69b2;
  --bs-warning: #ff9761;
  --bs-danger: #fc3f59;
  --bs-light: #e9ecef;
  --bs-dark: #000;
  --bs-white: #fff;
  --bs-upopi: #16aee5;
  --bs-cinema: #9e6996;
  --bs-patrimoine: #53b166;
  --bs-animation: #ff7352;
  --bs-education: #59a5a0;
  --bs-agenda: #506fa4;
  --bs-livre: #c64766;
  --bs-pro: #ffee00;
  --bs-primary-rgb: 18, 18, 18;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 83, 177, 102;
  --bs-info-rgb: 63, 105, 178;
  --bs-warning-rgb: 255, 151, 97;
  --bs-danger-rgb: 252, 63, 89;
  --bs-light-rgb: 233, 236, 239;
  --bs-dark-rgb: 0, 0, 0;
  --bs-white-rgb: 255, 255, 255;
  --bs-upopi-rgb: 22, 174, 229;
  --bs-cinema-rgb: 158, 105, 150;
  --bs-patrimoine-rgb: 83, 177, 102;
  --bs-animation-rgb: 255, 115, 82;
  --bs-education-rgb: 89, 165, 160;
  --bs-agenda-rgb: 80, 111, 164;
  --bs-livre-rgb: 198, 71, 102;
  --bs-pro-rgb: 255, 238, 0;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 18, 18, 18;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: "PTRoot", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: PTRoot, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #121212;
  --bs-body-bg: #fff;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 2.5rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.1;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.15;
}

h1, .h1 {
  font-size: calc(1.35rem + 1.2vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.25rem;
  }
}

h2, .h2 {
  font-size: calc(1.31rem + 0.72vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.85rem;
  }
}

h3, .h3 {
  font-size: calc(1.285rem + 0.42vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.6rem;
  }
}

h4, .h4 {
  font-size: calc(1.26rem + 0.12vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.35rem;
  }
}

h5, .h5 {
  font-size: 1.2rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #121212;
  text-decoration: underline;
}
a:hover {
  color: #0e0e0e;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #121212;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.15;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 3rem;
  }
}

.display-2 {
  font-size: calc(1.325rem + 0.9vw);
  font-weight: 300;
  line-height: 1.15;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 2rem;
  }
}

.display-3 {
  font-size: calc(1.3rem + 0.6vw);
  font-weight: 300;
  line-height: 1.15;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 1.75rem;
  }
}

.display-4 {
  font-size: calc(1.26rem + 0.12vw);
  font-weight: 300;
  line-height: 1.15;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 1.35rem;
  }
}

.display-5 {
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.15;
}

.display-6 {
  font-size: 0.85rem;
  font-weight: 300;
  line-height: 1.15;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 2rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 2rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 3rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 3rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 4.5rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 4.5rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 6rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 6rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 8rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 8rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 10rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 10rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 2rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 2rem;
  }

  .g-sm-6,
.gx-sm-6 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-6,
.gy-sm-6 {
    --bs-gutter-y: 3rem;
  }

  .g-sm-7,
.gx-sm-7 {
    --bs-gutter-x: 4.5rem;
  }

  .g-sm-7,
.gy-sm-7 {
    --bs-gutter-y: 4.5rem;
  }

  .g-sm-8,
.gx-sm-8 {
    --bs-gutter-x: 6rem;
  }

  .g-sm-8,
.gy-sm-8 {
    --bs-gutter-y: 6rem;
  }

  .g-sm-9,
.gx-sm-9 {
    --bs-gutter-x: 8rem;
  }

  .g-sm-9,
.gy-sm-9 {
    --bs-gutter-y: 8rem;
  }

  .g-sm-10,
.gx-sm-10 {
    --bs-gutter-x: 10rem;
  }

  .g-sm-10,
.gy-sm-10 {
    --bs-gutter-y: 10rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 2rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 2rem;
  }

  .g-md-6,
.gx-md-6 {
    --bs-gutter-x: 3rem;
  }

  .g-md-6,
.gy-md-6 {
    --bs-gutter-y: 3rem;
  }

  .g-md-7,
.gx-md-7 {
    --bs-gutter-x: 4.5rem;
  }

  .g-md-7,
.gy-md-7 {
    --bs-gutter-y: 4.5rem;
  }

  .g-md-8,
.gx-md-8 {
    --bs-gutter-x: 6rem;
  }

  .g-md-8,
.gy-md-8 {
    --bs-gutter-y: 6rem;
  }

  .g-md-9,
.gx-md-9 {
    --bs-gutter-x: 8rem;
  }

  .g-md-9,
.gy-md-9 {
    --bs-gutter-y: 8rem;
  }

  .g-md-10,
.gx-md-10 {
    --bs-gutter-x: 10rem;
  }

  .g-md-10,
.gy-md-10 {
    --bs-gutter-y: 10rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 2rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 2rem;
  }

  .g-lg-6,
.gx-lg-6 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-6,
.gy-lg-6 {
    --bs-gutter-y: 3rem;
  }

  .g-lg-7,
.gx-lg-7 {
    --bs-gutter-x: 4.5rem;
  }

  .g-lg-7,
.gy-lg-7 {
    --bs-gutter-y: 4.5rem;
  }

  .g-lg-8,
.gx-lg-8 {
    --bs-gutter-x: 6rem;
  }

  .g-lg-8,
.gy-lg-8 {
    --bs-gutter-y: 6rem;
  }

  .g-lg-9,
.gx-lg-9 {
    --bs-gutter-x: 8rem;
  }

  .g-lg-9,
.gy-lg-9 {
    --bs-gutter-y: 8rem;
  }

  .g-lg-10,
.gx-lg-10 {
    --bs-gutter-x: 10rem;
  }

  .g-lg-10,
.gy-lg-10 {
    --bs-gutter-y: 10rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 2rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 2rem;
  }

  .g-xl-6,
.gx-xl-6 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-6,
.gy-xl-6 {
    --bs-gutter-y: 3rem;
  }

  .g-xl-7,
.gx-xl-7 {
    --bs-gutter-x: 4.5rem;
  }

  .g-xl-7,
.gy-xl-7 {
    --bs-gutter-y: 4.5rem;
  }

  .g-xl-8,
.gx-xl-8 {
    --bs-gutter-x: 6rem;
  }

  .g-xl-8,
.gy-xl-8 {
    --bs-gutter-y: 6rem;
  }

  .g-xl-9,
.gx-xl-9 {
    --bs-gutter-x: 8rem;
  }

  .g-xl-9,
.gy-xl-9 {
    --bs-gutter-y: 8rem;
  }

  .g-xl-10,
.gx-xl-10 {
    --bs-gutter-x: 10rem;
  }

  .g-xl-10,
.gy-xl-10 {
    --bs-gutter-y: 10rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 2rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 2rem;
  }

  .g-xxl-6,
.gx-xxl-6 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-6,
.gy-xxl-6 {
    --bs-gutter-y: 3rem;
  }

  .g-xxl-7,
.gx-xxl-7 {
    --bs-gutter-x: 4.5rem;
  }

  .g-xxl-7,
.gy-xxl-7 {
    --bs-gutter-y: 4.5rem;
  }

  .g-xxl-8,
.gx-xxl-8 {
    --bs-gutter-x: 6rem;
  }

  .g-xxl-8,
.gy-xxl-8 {
    --bs-gutter-y: 6rem;
  }

  .g-xxl-9,
.gx-xxl-9 {
    --bs-gutter-x: 8rem;
  }

  .g-xxl-9,
.gy-xxl-9 {
    --bs-gutter-y: 8rem;
  }

  .g-xxl-10,
.gx-xxl-10 {
    --bs-gutter-x: 10rem;
  }

  .g-xxl-10,
.gy-xxl-10 {
    --bs-gutter-y: 10rem;
  }
}
@media (min-width: 1700px) {
  .col-3xl {
    flex: 1 0 0%;
  }

  .row-cols-3xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-3xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-3xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-3xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-3xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-3xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-3xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-3xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-3xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-3xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-3xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-3xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-3xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-3xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-3xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-3xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-3xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-3xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-3xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-3xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-3xl-0 {
    margin-left: 0;
  }

  .offset-3xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-3xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-3xl-3 {
    margin-left: 25%;
  }

  .offset-3xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-3xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-3xl-6 {
    margin-left: 50%;
  }

  .offset-3xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-3xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-3xl-9 {
    margin-left: 75%;
  }

  .offset-3xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-3xl-11 {
    margin-left: 91.66666667%;
  }

  .g-3xl-0,
.gx-3xl-0 {
    --bs-gutter-x: 0;
  }

  .g-3xl-0,
.gy-3xl-0 {
    --bs-gutter-y: 0;
  }

  .g-3xl-1,
.gx-3xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-3xl-1,
.gy-3xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-3xl-2,
.gx-3xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-3xl-2,
.gy-3xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-3xl-3,
.gx-3xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-3xl-3,
.gy-3xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-3xl-4,
.gx-3xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-3xl-4,
.gy-3xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-3xl-5,
.gx-3xl-5 {
    --bs-gutter-x: 2rem;
  }

  .g-3xl-5,
.gy-3xl-5 {
    --bs-gutter-y: 2rem;
  }

  .g-3xl-6,
.gx-3xl-6 {
    --bs-gutter-x: 3rem;
  }

  .g-3xl-6,
.gy-3xl-6 {
    --bs-gutter-y: 3rem;
  }

  .g-3xl-7,
.gx-3xl-7 {
    --bs-gutter-x: 4.5rem;
  }

  .g-3xl-7,
.gy-3xl-7 {
    --bs-gutter-y: 4.5rem;
  }

  .g-3xl-8,
.gx-3xl-8 {
    --bs-gutter-x: 6rem;
  }

  .g-3xl-8,
.gy-3xl-8 {
    --bs-gutter-y: 6rem;
  }

  .g-3xl-9,
.gx-3xl-9 {
    --bs-gutter-x: 8rem;
  }

  .g-3xl-9,
.gy-3xl-9 {
    --bs-gutter-y: 8rem;
  }

  .g-3xl-10,
.gx-3xl-10 {
    --bs-gutter-x: 10rem;
  }

  .g-3xl-10,
.gy-3xl-10 {
    --bs-gutter-y: 10rem;
  }
}
@media (min-width: 2000px) {
  .col-4xl {
    flex: 1 0 0%;
  }

  .row-cols-4xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-4xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-4xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-4xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-4xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-4xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-4xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-4xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-4xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-4xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-4xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-4xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-4xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-4xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-4xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-4xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-4xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-4xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-4xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-4xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-4xl-0 {
    margin-left: 0;
  }

  .offset-4xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-4xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-4xl-3 {
    margin-left: 25%;
  }

  .offset-4xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-4xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-4xl-6 {
    margin-left: 50%;
  }

  .offset-4xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-4xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-4xl-9 {
    margin-left: 75%;
  }

  .offset-4xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-4xl-11 {
    margin-left: 91.66666667%;
  }

  .g-4xl-0,
.gx-4xl-0 {
    --bs-gutter-x: 0;
  }

  .g-4xl-0,
.gy-4xl-0 {
    --bs-gutter-y: 0;
  }

  .g-4xl-1,
.gx-4xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-4xl-1,
.gy-4xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-4xl-2,
.gx-4xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-4xl-2,
.gy-4xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-4xl-3,
.gx-4xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-4xl-3,
.gy-4xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-4xl-4,
.gx-4xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-4xl-4,
.gy-4xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-4xl-5,
.gx-4xl-5 {
    --bs-gutter-x: 2rem;
  }

  .g-4xl-5,
.gy-4xl-5 {
    --bs-gutter-y: 2rem;
  }

  .g-4xl-6,
.gx-4xl-6 {
    --bs-gutter-x: 3rem;
  }

  .g-4xl-6,
.gy-4xl-6 {
    --bs-gutter-y: 3rem;
  }

  .g-4xl-7,
.gx-4xl-7 {
    --bs-gutter-x: 4.5rem;
  }

  .g-4xl-7,
.gy-4xl-7 {
    --bs-gutter-y: 4.5rem;
  }

  .g-4xl-8,
.gx-4xl-8 {
    --bs-gutter-x: 6rem;
  }

  .g-4xl-8,
.gy-4xl-8 {
    --bs-gutter-y: 6rem;
  }

  .g-4xl-9,
.gx-4xl-9 {
    --bs-gutter-x: 8rem;
  }

  .g-4xl-9,
.gy-4xl-9 {
    --bs-gutter-y: 8rem;
  }

  .g-4xl-10,
.gx-4xl-10 {
    --bs-gutter-x: 10rem;
  }

  .g-4xl-10,
.gy-4xl-10 {
    --bs-gutter-y: 10rem;
  }
}
.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #121212;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #121212;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #121212;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #121212;
  vertical-align: top;
  border-color: #dee2e6;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:first-child) {
  border-top: 2px solid currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #d0d0d0;
  --bs-table-striped-bg: #c6c6c6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bbbbbb;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: silver;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bbbbbb;
}

.table-secondary {
  --bs-table-bg: #e2e3e5;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cbccce;
}

.table-success {
  --bs-table-bg: #ddefe0;
  --bs-table-striped-bg: #d2e3d5;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c7d7ca;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ccddcf;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c7d7ca;
}

.table-info {
  --bs-table-bg: #d9e1f0;
  --bs-table-striped-bg: #ced6e4;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c3cbd8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c9d0de;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c3cbd8;
}

.table-warning {
  --bs-table-bg: #ffeadf;
  --bs-table-striped-bg: #f2ded4;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6d3c9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecd8ce;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6d3c9;
}

.table-danger {
  --bs-table-bg: #fed9de;
  --bs-table-striped-bg: #f1ced3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e5c3c8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ebc9cd;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e5c3c8;
}

.table-light {
  --bs-table-bg: #e9ecef;
  --bs-table-striped-bg: #dde0e3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d2d4d7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d8dadd;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #d2d4d7;
}

.table-dark {
  --bs-table-bg: #000;
  --bs-table-striped-bg: #0d0d0d;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #1a1a1a;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #131313;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #1a1a1a;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1699.98px) {
  .table-responsive-3xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1999.98px) {
  .table-responsive-4xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #121212;
  background-color: #e9ecef;
  background-clip: padding-box;
  border: 1px solid transparent;
  appearance: none;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #121212;
  background-color: #e9ecef;
  border-color: transparent;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(18, 18, 18, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #121212;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}
.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #121212;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #121212;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #121212;
  background-color: #e9ecef;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: transparent;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(18, 18, 18, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #121212;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #e9ecef;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: transparent;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(18, 18, 18, 0.25);
}
.form-check-input:checked {
  background-color: #121212;
  border-color: #121212;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #121212;
  border-color: #121212;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='transparent'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.35;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(18, 18, 18, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(18, 18, 18, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #121212;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b8b8b8;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #121212;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b8b8b8;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #999999;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #999999;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #121212;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #53b166;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #000;
  background-color: rgba(83, 177, 102, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #53b166;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2353b166' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #53b166;
  box-shadow: 0 0 0 0.25rem rgba(83, 177, 102, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #53b166;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2353b166' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #53b166;
  box-shadow: 0 0 0 0.25rem rgba(83, 177, 102, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #53b166;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #53b166;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(83, 177, 102, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #53b166;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #fc3f59;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #000;
  background-color: rgba(252, 63, 89, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #fc3f59;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23fc3f59'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23fc3f59' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #fc3f59;
  box-shadow: 0 0 0 0.25rem rgba(252, 63, 89, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #fc3f59;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23fc3f59'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23fc3f59' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #fc3f59;
  box-shadow: 0 0 0 0.25rem rgba(252, 63, 89, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #fc3f59;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #fc3f59;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(252, 63, 89, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #fc3f59;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-weight: bold;
  line-height: 1.5;
  color: #121212;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 1.3em;
  font-size: 1rem;
  border-radius: 50rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #121212;
}
.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(18, 18, 18, 0.25);
}
.btn-check:checked + .btn, .btn-check:active + .btn, .btn:active, .btn.active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-check:checked + .btn:focus, .btn-check:active + .btn:focus, .btn:active:focus, .btn.active:focus {
  box-shadow: 0 0 0 0.25rem rgba(18, 18, 18, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.35;
  box-shadow: none;
}

.btn-primary {
  color: #fff;
  background-color: #121212;
  border-color: #121212;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-primary:hover {
  color: #fff;
  background-color: #0f0f0f;
  border-color: #0e0e0e;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #0f0f0f;
  border-color: #0e0e0e;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(54, 54, 54, 0.5);
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0e0e0e;
  border-color: #0e0e0e;
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(54, 54, 54, 0.5);
}
.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #121212;
  border-color: #121212;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #565e64;
  border-color: #51585e;
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}
.btn-secondary:disabled, .btn-secondary.disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-success {
  color: #000;
  background-color: #53b166;
  border-color: #53b166;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-success:hover {
  color: #000;
  background-color: #7ec58c;
  border-color: #64b975;
}
.btn-check:focus + .btn-success, .btn-success:focus {
  color: #000;
  background-color: #7ec58c;
  border-color: #64b975;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(71, 150, 87, 0.5);
}
.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: #000;
  background-color: #75c185;
  border-color: #64b975;
}
.btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(71, 150, 87, 0.5);
}
.btn-success:disabled, .btn-success.disabled {
  color: #000;
  background-color: #53b166;
  border-color: #53b166;
}

.btn-info {
  color: #fff;
  background-color: #3f69b2;
  border-color: #3f69b2;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-info:hover {
  color: #fff;
  background-color: #365997;
  border-color: #32548e;
}
.btn-check:focus + .btn-info, .btn-info:focus {
  color: #fff;
  background-color: #365997;
  border-color: #32548e;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(92, 128, 190, 0.5);
}
.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #32548e;
  border-color: #2f4f86;
}
.btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(92, 128, 190, 0.5);
}
.btn-info:disabled, .btn-info.disabled {
  color: #fff;
  background-color: #3f69b2;
  border-color: #3f69b2;
}

.btn-warning {
  color: #000;
  background-color: #ff9761;
  border-color: #ff9761;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-warning:hover {
  color: #000;
  background-color: #ffb189;
  border-color: #ffa171;
}
.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #000;
  background-color: #ffb189;
  border-color: #ffa171;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(217, 128, 82, 0.5);
}
.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #ffac81;
  border-color: #ffa171;
}
.btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(217, 128, 82, 0.5);
}
.btn-warning:disabled, .btn-warning.disabled {
  color: #000;
  background-color: #ff9761;
  border-color: #ff9761;
}

.btn-danger {
  color: #000;
  background-color: #fc3f59;
  border-color: #fc3f59;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-danger:hover {
  color: #000;
  background-color: #fd6f83;
  border-color: #fc526a;
}
.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #000;
  background-color: #fd6f83;
  border-color: #fc526a;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(214, 54, 76, 0.5);
}
.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: #000;
  background-color: #fd657a;
  border-color: #fc526a;
}
.btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(214, 54, 76, 0.5);
}
.btn-danger:disabled, .btn-danger.disabled {
  color: #000;
  background-color: #fc3f59;
  border-color: #fc3f59;
}

.btn-light {
  color: #000;
  background-color: #e9ecef;
  border-color: #e9ecef;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-light:hover {
  color: #000;
  background-color: #eff1f3;
  border-color: #ebeef1;
}
.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000;
  background-color: #eff1f3;
  border-color: #ebeef1;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(198, 201, 203, 0.5);
}
.btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #edf0f2;
  border-color: #ebeef1;
}
.btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(198, 201, 203, 0.5);
}
.btn-light:disabled, .btn-light.disabled {
  color: #000;
  background-color: #e9ecef;
  border-color: #e9ecef;
}

.btn-dark {
  color: #fff;
  background-color: #000;
  border-color: #000;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-dark:hover {
  color: #fff;
  background-color: black;
  border-color: black;
}
.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: black;
  border-color: black;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(38, 38, 38, 0.5);
}
.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: black;
  border-color: black;
}
.btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(38, 38, 38, 0.5);
}
.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-white {
  color: #000;
  background-color: #fff;
  border-color: #fff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-white:hover {
  color: #000;
  background-color: white;
  border-color: white;
}
.btn-check:focus + .btn-white, .btn-white:focus {
  color: #000;
  background-color: white;
  border-color: white;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(217, 217, 217, 0.5);
}
.btn-check:checked + .btn-white, .btn-check:active + .btn-white, .btn-white:active, .btn-white.active, .show > .btn-white.dropdown-toggle {
  color: #000;
  background-color: white;
  border-color: white;
}
.btn-check:checked + .btn-white:focus, .btn-check:active + .btn-white:focus, .btn-white:active:focus, .btn-white.active:focus, .show > .btn-white.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(217, 217, 217, 0.5);
}
.btn-white:disabled, .btn-white.disabled {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.btn-upopi {
  color: #000;
  background-color: #16aee5;
  border-color: #16aee5;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-upopi:hover {
  color: #000;
  background-color: #50c2ec;
  border-color: #2db6e8;
}
.btn-check:focus + .btn-upopi, .btn-upopi:focus {
  color: #000;
  background-color: #50c2ec;
  border-color: #2db6e8;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(19, 148, 195, 0.5);
}
.btn-check:checked + .btn-upopi, .btn-check:active + .btn-upopi, .btn-upopi:active, .btn-upopi.active, .show > .btn-upopi.dropdown-toggle {
  color: #000;
  background-color: #45beea;
  border-color: #2db6e8;
}
.btn-check:checked + .btn-upopi:focus, .btn-check:active + .btn-upopi:focus, .btn-upopi:active:focus, .btn-upopi.active:focus, .show > .btn-upopi.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(19, 148, 195, 0.5);
}
.btn-upopi:disabled, .btn-upopi.disabled {
  color: #000;
  background-color: #16aee5;
  border-color: #16aee5;
}

.btn-cinema {
  color: #000;
  background-color: #9e6996;
  border-color: #9e6996;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-cinema:hover {
  color: #000;
  background-color: #b68fb0;
  border-color: #a878a1;
}
.btn-check:focus + .btn-cinema, .btn-cinema:focus {
  color: #000;
  background-color: #b68fb0;
  border-color: #a878a1;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(134, 89, 128, 0.5);
}
.btn-check:checked + .btn-cinema, .btn-check:active + .btn-cinema, .btn-cinema:active, .btn-cinema.active, .show > .btn-cinema.dropdown-toggle {
  color: #000;
  background-color: #b187ab;
  border-color: #a878a1;
}
.btn-check:checked + .btn-cinema:focus, .btn-check:active + .btn-cinema:focus, .btn-cinema:active:focus, .btn-cinema.active:focus, .show > .btn-cinema.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(134, 89, 128, 0.5);
}
.btn-cinema:disabled, .btn-cinema.disabled {
  color: #000;
  background-color: #9e6996;
  border-color: #9e6996;
}

.btn-patrimoine {
  color: #000;
  background-color: #53b166;
  border-color: #53b166;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-patrimoine:hover {
  color: #000;
  background-color: #7ec58c;
  border-color: #64b975;
}
.btn-check:focus + .btn-patrimoine, .btn-patrimoine:focus {
  color: #000;
  background-color: #7ec58c;
  border-color: #64b975;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(71, 150, 87, 0.5);
}
.btn-check:checked + .btn-patrimoine, .btn-check:active + .btn-patrimoine, .btn-patrimoine:active, .btn-patrimoine.active, .show > .btn-patrimoine.dropdown-toggle {
  color: #000;
  background-color: #75c185;
  border-color: #64b975;
}
.btn-check:checked + .btn-patrimoine:focus, .btn-check:active + .btn-patrimoine:focus, .btn-patrimoine:active:focus, .btn-patrimoine.active:focus, .show > .btn-patrimoine.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(71, 150, 87, 0.5);
}
.btn-patrimoine:disabled, .btn-patrimoine.disabled {
  color: #000;
  background-color: #53b166;
  border-color: #53b166;
}

.btn-animation {
  color: #000;
  background-color: #ff7352;
  border-color: #ff7352;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-animation:hover {
  color: #000;
  background-color: #ff967d;
  border-color: #ff8163;
}
.btn-check:focus + .btn-animation, .btn-animation:focus {
  color: #000;
  background-color: #ff967d;
  border-color: #ff8163;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(217, 98, 70, 0.5);
}
.btn-check:checked + .btn-animation, .btn-check:active + .btn-animation, .btn-animation:active, .btn-animation.active, .show > .btn-animation.dropdown-toggle {
  color: #000;
  background-color: #ff8f75;
  border-color: #ff8163;
}
.btn-check:checked + .btn-animation:focus, .btn-check:active + .btn-animation:focus, .btn-animation:active:focus, .btn-animation.active:focus, .show > .btn-animation.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(217, 98, 70, 0.5);
}
.btn-animation:disabled, .btn-animation.disabled {
  color: #000;
  background-color: #ff7352;
  border-color: #ff7352;
}

.btn-education {
  color: #000;
  background-color: #59a5a0;
  border-color: #59a5a0;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-education:hover {
  color: #000;
  background-color: #83bcb8;
  border-color: #6aaeaa;
}
.btn-check:focus + .btn-education, .btn-education:focus {
  color: #000;
  background-color: #83bcb8;
  border-color: #6aaeaa;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(76, 140, 136, 0.5);
}
.btn-check:checked + .btn-education, .btn-check:active + .btn-education, .btn-education:active, .btn-education.active, .show > .btn-education.dropdown-toggle {
  color: #000;
  background-color: #7ab7b3;
  border-color: #6aaeaa;
}
.btn-check:checked + .btn-education:focus, .btn-check:active + .btn-education:focus, .btn-education:active:focus, .btn-education.active:focus, .show > .btn-education.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(76, 140, 136, 0.5);
}
.btn-education:disabled, .btn-education.disabled {
  color: #000;
  background-color: #59a5a0;
  border-color: #59a5a0;
}

.btn-agenda {
  color: #fff;
  background-color: #506fa4;
  border-color: #506fa4;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-agenda:hover {
  color: #fff;
  background-color: #445e8b;
  border-color: #405983;
}
.btn-check:focus + .btn-agenda, .btn-agenda:focus {
  color: #fff;
  background-color: #445e8b;
  border-color: #405983;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(106, 133, 178, 0.5);
}
.btn-check:checked + .btn-agenda, .btn-check:active + .btn-agenda, .btn-agenda:active, .btn-agenda.active, .show > .btn-agenda.dropdown-toggle {
  color: #fff;
  background-color: #405983;
  border-color: #3c537b;
}
.btn-check:checked + .btn-agenda:focus, .btn-check:active + .btn-agenda:focus, .btn-agenda:active:focus, .btn-agenda.active:focus, .show > .btn-agenda.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(106, 133, 178, 0.5);
}
.btn-agenda:disabled, .btn-agenda.disabled {
  color: #fff;
  background-color: #506fa4;
  border-color: #506fa4;
}

.btn-livre {
  color: #fff;
  background-color: #c64766;
  border-color: #c64766;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-livre:hover {
  color: #fff;
  background-color: #a83c57;
  border-color: #9e3952;
}
.btn-check:focus + .btn-livre, .btn-livre:focus {
  color: #fff;
  background-color: #a83c57;
  border-color: #9e3952;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(207, 99, 125, 0.5);
}
.btn-check:checked + .btn-livre, .btn-check:active + .btn-livre, .btn-livre:active, .btn-livre.active, .show > .btn-livre.dropdown-toggle {
  color: #fff;
  background-color: #9e3952;
  border-color: #95354d;
}
.btn-check:checked + .btn-livre:focus, .btn-check:active + .btn-livre:focus, .btn-livre:active:focus, .btn-livre.active:focus, .show > .btn-livre.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(207, 99, 125, 0.5);
}
.btn-livre:disabled, .btn-livre.disabled {
  color: #fff;
  background-color: #c64766;
  border-color: #c64766;
}

.btn-pro {
  color: #000;
  background-color: #ffee00;
  border-color: #ffee00;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-pro:hover {
  color: #000;
  background-color: #fff240;
  border-color: #fff01a;
}
.btn-check:focus + .btn-pro, .btn-pro:focus {
  color: #000;
  background-color: #fff240;
  border-color: #fff01a;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(217, 202, 0, 0.5);
}
.btn-check:checked + .btn-pro, .btn-check:active + .btn-pro, .btn-pro:active, .btn-pro.active, .show > .btn-pro.dropdown-toggle {
  color: #000;
  background-color: #fff133;
  border-color: #fff01a;
}
.btn-check:checked + .btn-pro:focus, .btn-check:active + .btn-pro:focus, .btn-pro:active:focus, .btn-pro.active:focus, .show > .btn-pro.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(217, 202, 0, 0.5);
}
.btn-pro:disabled, .btn-pro.disabled {
  color: #000;
  background-color: #ffee00;
  border-color: #ffee00;
}

.btn-outline-primary {
  color: #121212;
  border-color: #121212;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #121212;
  border-color: #121212;
}
.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(18, 18, 18, 0.5);
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #121212;
  border-color: #121212;
}
.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(18, 18, 18, 0.5);
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #121212;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}
.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-success {
  color: #53b166;
  border-color: #53b166;
}
.btn-outline-success:hover {
  color: #000;
  background-color: #53b166;
  border-color: #53b166;
}
.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(83, 177, 102, 0.5);
}
.btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #000;
  background-color: #53b166;
  border-color: #53b166;
}
.btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(83, 177, 102, 0.5);
}
.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #53b166;
  background-color: transparent;
}

.btn-outline-info {
  color: #3f69b2;
  border-color: #3f69b2;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #3f69b2;
  border-color: #3f69b2;
}
.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(63, 105, 178, 0.5);
}
.btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #fff;
  background-color: #3f69b2;
  border-color: #3f69b2;
}
.btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(63, 105, 178, 0.5);
}
.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #3f69b2;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ff9761;
  border-color: #ff9761;
}
.btn-outline-warning:hover {
  color: #000;
  background-color: #ff9761;
  border-color: #ff9761;
}
.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 151, 97, 0.5);
}
.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #ff9761;
  border-color: #ff9761;
}
.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(255, 151, 97, 0.5);
}
.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #ff9761;
  background-color: transparent;
}

.btn-outline-danger {
  color: #fc3f59;
  border-color: #fc3f59;
}
.btn-outline-danger:hover {
  color: #000;
  background-color: #fc3f59;
  border-color: #fc3f59;
}
.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(252, 63, 89, 0.5);
}
.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #000;
  background-color: #fc3f59;
  border-color: #fc3f59;
}
.btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(252, 63, 89, 0.5);
}
.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #fc3f59;
  background-color: transparent;
}

.btn-outline-light {
  color: #e9ecef;
  border-color: #e9ecef;
}
.btn-outline-light:hover {
  color: #000;
  background-color: #e9ecef;
  border-color: #e9ecef;
}
.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(233, 236, 239, 0.5);
}
.btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #e9ecef;
  border-color: #e9ecef;
}
.btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(233, 236, 239, 0.5);
}
.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #e9ecef;
  background-color: transparent;
}

.btn-outline-dark {
  color: #000;
  border-color: #000;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.5);
}
.btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-check:checked + .btn-outline-dark:focus, .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(0, 0, 0, 0.5);
}
.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #000;
  background-color: transparent;
}

.btn-outline-white {
  color: #fff;
  border-color: #fff;
}
.btn-outline-white:hover {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}
.btn-check:focus + .btn-outline-white, .btn-outline-white:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-check:checked + .btn-outline-white, .btn-check:active + .btn-outline-white, .btn-outline-white:active, .btn-outline-white.active, .btn-outline-white.dropdown-toggle.show {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}
.btn-check:checked + .btn-outline-white:focus, .btn-check:active + .btn-outline-white:focus, .btn-outline-white:active:focus, .btn-outline-white.active:focus, .btn-outline-white.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-outline-white:disabled, .btn-outline-white.disabled {
  color: #fff;
  background-color: transparent;
}

.btn-outline-upopi {
  color: #16aee5;
  border-color: #16aee5;
}
.btn-outline-upopi:hover {
  color: #000;
  background-color: #16aee5;
  border-color: #16aee5;
}
.btn-check:focus + .btn-outline-upopi, .btn-outline-upopi:focus {
  box-shadow: 0 0 0 0.25rem rgba(22, 174, 229, 0.5);
}
.btn-check:checked + .btn-outline-upopi, .btn-check:active + .btn-outline-upopi, .btn-outline-upopi:active, .btn-outline-upopi.active, .btn-outline-upopi.dropdown-toggle.show {
  color: #000;
  background-color: #16aee5;
  border-color: #16aee5;
}
.btn-check:checked + .btn-outline-upopi:focus, .btn-check:active + .btn-outline-upopi:focus, .btn-outline-upopi:active:focus, .btn-outline-upopi.active:focus, .btn-outline-upopi.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(22, 174, 229, 0.5);
}
.btn-outline-upopi:disabled, .btn-outline-upopi.disabled {
  color: #16aee5;
  background-color: transparent;
}

.btn-outline-cinema {
  color: #9e6996;
  border-color: #9e6996;
}
.btn-outline-cinema:hover {
  color: #000;
  background-color: #9e6996;
  border-color: #9e6996;
}
.btn-check:focus + .btn-outline-cinema, .btn-outline-cinema:focus {
  box-shadow: 0 0 0 0.25rem rgba(158, 105, 150, 0.5);
}
.btn-check:checked + .btn-outline-cinema, .btn-check:active + .btn-outline-cinema, .btn-outline-cinema:active, .btn-outline-cinema.active, .btn-outline-cinema.dropdown-toggle.show {
  color: #000;
  background-color: #9e6996;
  border-color: #9e6996;
}
.btn-check:checked + .btn-outline-cinema:focus, .btn-check:active + .btn-outline-cinema:focus, .btn-outline-cinema:active:focus, .btn-outline-cinema.active:focus, .btn-outline-cinema.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(158, 105, 150, 0.5);
}
.btn-outline-cinema:disabled, .btn-outline-cinema.disabled {
  color: #9e6996;
  background-color: transparent;
}

.btn-outline-patrimoine {
  color: #53b166;
  border-color: #53b166;
}
.btn-outline-patrimoine:hover {
  color: #000;
  background-color: #53b166;
  border-color: #53b166;
}
.btn-check:focus + .btn-outline-patrimoine, .btn-outline-patrimoine:focus {
  box-shadow: 0 0 0 0.25rem rgba(83, 177, 102, 0.5);
}
.btn-check:checked + .btn-outline-patrimoine, .btn-check:active + .btn-outline-patrimoine, .btn-outline-patrimoine:active, .btn-outline-patrimoine.active, .btn-outline-patrimoine.dropdown-toggle.show {
  color: #000;
  background-color: #53b166;
  border-color: #53b166;
}
.btn-check:checked + .btn-outline-patrimoine:focus, .btn-check:active + .btn-outline-patrimoine:focus, .btn-outline-patrimoine:active:focus, .btn-outline-patrimoine.active:focus, .btn-outline-patrimoine.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(83, 177, 102, 0.5);
}
.btn-outline-patrimoine:disabled, .btn-outline-patrimoine.disabled {
  color: #53b166;
  background-color: transparent;
}

.btn-outline-animation {
  color: #ff7352;
  border-color: #ff7352;
}
.btn-outline-animation:hover {
  color: #000;
  background-color: #ff7352;
  border-color: #ff7352;
}
.btn-check:focus + .btn-outline-animation, .btn-outline-animation:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 115, 82, 0.5);
}
.btn-check:checked + .btn-outline-animation, .btn-check:active + .btn-outline-animation, .btn-outline-animation:active, .btn-outline-animation.active, .btn-outline-animation.dropdown-toggle.show {
  color: #000;
  background-color: #ff7352;
  border-color: #ff7352;
}
.btn-check:checked + .btn-outline-animation:focus, .btn-check:active + .btn-outline-animation:focus, .btn-outline-animation:active:focus, .btn-outline-animation.active:focus, .btn-outline-animation.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(255, 115, 82, 0.5);
}
.btn-outline-animation:disabled, .btn-outline-animation.disabled {
  color: #ff7352;
  background-color: transparent;
}

.btn-outline-education {
  color: #59a5a0;
  border-color: #59a5a0;
}
.btn-outline-education:hover {
  color: #000;
  background-color: #59a5a0;
  border-color: #59a5a0;
}
.btn-check:focus + .btn-outline-education, .btn-outline-education:focus {
  box-shadow: 0 0 0 0.25rem rgba(89, 165, 160, 0.5);
}
.btn-check:checked + .btn-outline-education, .btn-check:active + .btn-outline-education, .btn-outline-education:active, .btn-outline-education.active, .btn-outline-education.dropdown-toggle.show {
  color: #000;
  background-color: #59a5a0;
  border-color: #59a5a0;
}
.btn-check:checked + .btn-outline-education:focus, .btn-check:active + .btn-outline-education:focus, .btn-outline-education:active:focus, .btn-outline-education.active:focus, .btn-outline-education.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(89, 165, 160, 0.5);
}
.btn-outline-education:disabled, .btn-outline-education.disabled {
  color: #59a5a0;
  background-color: transparent;
}

.btn-outline-agenda {
  color: #506fa4;
  border-color: #506fa4;
}
.btn-outline-agenda:hover {
  color: #fff;
  background-color: #506fa4;
  border-color: #506fa4;
}
.btn-check:focus + .btn-outline-agenda, .btn-outline-agenda:focus {
  box-shadow: 0 0 0 0.25rem rgba(80, 111, 164, 0.5);
}
.btn-check:checked + .btn-outline-agenda, .btn-check:active + .btn-outline-agenda, .btn-outline-agenda:active, .btn-outline-agenda.active, .btn-outline-agenda.dropdown-toggle.show {
  color: #fff;
  background-color: #506fa4;
  border-color: #506fa4;
}
.btn-check:checked + .btn-outline-agenda:focus, .btn-check:active + .btn-outline-agenda:focus, .btn-outline-agenda:active:focus, .btn-outline-agenda.active:focus, .btn-outline-agenda.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(80, 111, 164, 0.5);
}
.btn-outline-agenda:disabled, .btn-outline-agenda.disabled {
  color: #506fa4;
  background-color: transparent;
}

.btn-outline-livre {
  color: #c64766;
  border-color: #c64766;
}
.btn-outline-livre:hover {
  color: #fff;
  background-color: #c64766;
  border-color: #c64766;
}
.btn-check:focus + .btn-outline-livre, .btn-outline-livre:focus {
  box-shadow: 0 0 0 0.25rem rgba(198, 71, 102, 0.5);
}
.btn-check:checked + .btn-outline-livre, .btn-check:active + .btn-outline-livre, .btn-outline-livre:active, .btn-outline-livre.active, .btn-outline-livre.dropdown-toggle.show {
  color: #fff;
  background-color: #c64766;
  border-color: #c64766;
}
.btn-check:checked + .btn-outline-livre:focus, .btn-check:active + .btn-outline-livre:focus, .btn-outline-livre:active:focus, .btn-outline-livre.active:focus, .btn-outline-livre.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(198, 71, 102, 0.5);
}
.btn-outline-livre:disabled, .btn-outline-livre.disabled {
  color: #c64766;
  background-color: transparent;
}

.btn-outline-pro {
  color: #ffee00;
  border-color: #ffee00;
}
.btn-outline-pro:hover {
  color: #000;
  background-color: #ffee00;
  border-color: #ffee00;
}
.btn-check:focus + .btn-outline-pro, .btn-outline-pro:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 238, 0, 0.5);
}
.btn-check:checked + .btn-outline-pro, .btn-check:active + .btn-outline-pro, .btn-outline-pro:active, .btn-outline-pro.active, .btn-outline-pro.dropdown-toggle.show {
  color: #000;
  background-color: #ffee00;
  border-color: #ffee00;
}
.btn-check:checked + .btn-outline-pro:focus, .btn-check:active + .btn-outline-pro:focus, .btn-outline-pro:active:focus, .btn-outline-pro.active:focus, .btn-outline-pro.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(255, 238, 0, 0.5);
}
.btn-outline-pro:disabled, .btn-outline-pro.disabled {
  color: #ffee00;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #121212;
  text-decoration: underline;
}
.btn-link:hover {
  color: #0e0e0e;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #121212;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: 0 15px 50px -10px rgba(0, 0, 0, 0.25);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1700px) {
  .dropdown-menu-3xl-start {
    --bs-position: start;
  }
  .dropdown-menu-3xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-3xl-end {
    --bs-position: end;
  }
  .dropdown-menu-3xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 2000px) {
  .dropdown-menu-4xl-start {
    --bs-position: start;
  }
  .dropdown-menu-4xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-4xl-end {
    --bs-position: end;
  }
  .dropdown-menu-4xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.5rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #121212;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #101010;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #121212;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #999999;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.5rem 1.5rem;
  color: #121212;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #121212;
}
.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #999999;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-header {
  color: #999999;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.975em;
  padding-left: 0.975em;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #999999;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: #0e0e0e;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #e9ecef;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #121212;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--site-color);
  background-color: transparent;
  border-color: var(--site-color);
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #121212;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: inherit;
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: inherit;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #121212;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #0e0e0e;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  color: #0e0e0e;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(18, 18, 18, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #121212;
  border-color: #121212;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #0b0b0b;
  background-color: #d0d0d0;
  border-color: #b8b8b8;
}
.alert-primary .alert-link {
  color: #090909;
}

.alert-secondary {
  color: #41464b;
  background-color: #e2e3e5;
  border-color: #d3d6d8;
}
.alert-secondary .alert-link {
  color: #34383c;
}

.alert-success {
  color: #326a3d;
  background-color: #ddefe0;
  border-color: #cbe8d1;
}
.alert-success .alert-link {
  color: #285531;
}

.alert-info {
  color: #263f6b;
  background-color: #d9e1f0;
  border-color: #c5d2e8;
}
.alert-info .alert-link {
  color: #1e3256;
}

.alert-warning {
  color: #995b3a;
  background-color: #ffeadf;
  border-color: #ffe0d0;
}
.alert-warning .alert-link {
  color: #7a492e;
}

.alert-danger {
  color: #972635;
  background-color: #fed9de;
  border-color: #fec5cd;
}
.alert-danger .alert-link {
  color: #791e2a;
}

.alert-light {
  color: #5d5e60;
  background-color: #fbfbfc;
  border-color: #f8f9fa;
}
.alert-light .alert-link {
  color: #4a4b4d;
}

.alert-dark {
  color: black;
  background-color: #cccccc;
  border-color: #b3b3b3;
}
.alert-dark .alert-link {
  color: black;
}

.alert-white {
  color: #666666;
  background-color: white;
  border-color: white;
}
.alert-white .alert-link {
  color: #525252;
}

.alert-upopi {
  color: #0d6889;
  background-color: #d0effa;
  border-color: #b9e7f7;
}
.alert-upopi .alert-link {
  color: #0a536e;
}

.alert-cinema {
  color: #5f3f5a;
  background-color: #ece1ea;
  border-color: #e2d2e0;
}
.alert-cinema .alert-link {
  color: #4c3248;
}

.alert-patrimoine {
  color: #326a3d;
  background-color: #ddefe0;
  border-color: #cbe8d1;
}
.alert-patrimoine .alert-link {
  color: #285531;
}

.alert-animation {
  color: #994531;
  background-color: #ffe3dc;
  border-color: #ffd5cb;
}
.alert-animation .alert-link {
  color: #7a3727;
}

.alert-education {
  color: #356360;
  background-color: #deedec;
  border-color: #cde4e3;
}
.alert-education .alert-link {
  color: #2a4f4d;
}

.alert-agenda {
  color: #304362;
  background-color: #dce2ed;
  border-color: #cbd4e4;
}
.alert-agenda .alert-link {
  color: #26364e;
}

.alert-livre {
  color: #772b3d;
  background-color: #f4dae0;
  border-color: #eec8d1;
}
.alert-livre .alert-link {
  color: #5f2231;
}

.alert-pro {
  color: #665f00;
  background-color: #fffccc;
  border-color: #fffab3;
}
.alert-pro .alert-link {
  color: #524c00;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #121212;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #121212;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #121212;
  border-color: #121212;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1700px) {
  .list-group-horizontal-3xl {
    flex-direction: row;
  }
  .list-group-horizontal-3xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-3xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-3xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-3xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-3xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 2000px) {
  .list-group-horizontal-4xl {
    flex-direction: row;
  }
  .list-group-horizontal-4xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-4xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-4xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-4xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-4xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #0b0b0b;
  background-color: #d0d0d0;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #0b0b0b;
  background-color: #bbbbbb;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #0b0b0b;
  border-color: #0b0b0b;
}

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.list-group-item-success {
  color: #326a3d;
  background-color: #ddefe0;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #326a3d;
  background-color: #c7d7ca;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #326a3d;
  border-color: #326a3d;
}

.list-group-item-info {
  color: #263f6b;
  background-color: #d9e1f0;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #263f6b;
  background-color: #c3cbd8;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #263f6b;
  border-color: #263f6b;
}

.list-group-item-warning {
  color: #995b3a;
  background-color: #ffeadf;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #995b3a;
  background-color: #e6d3c9;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #995b3a;
  border-color: #995b3a;
}

.list-group-item-danger {
  color: #972635;
  background-color: #fed9de;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #972635;
  background-color: #e5c3c8;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #972635;
  border-color: #972635;
}

.list-group-item-light {
  color: #5d5e60;
  background-color: #fbfbfc;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #5d5e60;
  background-color: #e2e2e3;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #5d5e60;
  border-color: #5d5e60;
}

.list-group-item-dark {
  color: black;
  background-color: #cccccc;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: black;
  background-color: #b8b8b8;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: black;
  border-color: black;
}

.list-group-item-white {
  color: #666666;
  background-color: white;
}
.list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
  color: #666666;
  background-color: #e6e6e6;
}
.list-group-item-white.list-group-item-action.active {
  color: #fff;
  background-color: #666666;
  border-color: #666666;
}

.list-group-item-upopi {
  color: #0d6889;
  background-color: #d0effa;
}
.list-group-item-upopi.list-group-item-action:hover, .list-group-item-upopi.list-group-item-action:focus {
  color: #0d6889;
  background-color: #bbd7e1;
}
.list-group-item-upopi.list-group-item-action.active {
  color: #fff;
  background-color: #0d6889;
  border-color: #0d6889;
}

.list-group-item-cinema {
  color: #5f3f5a;
  background-color: #ece1ea;
}
.list-group-item-cinema.list-group-item-action:hover, .list-group-item-cinema.list-group-item-action:focus {
  color: #5f3f5a;
  background-color: #d4cbd3;
}
.list-group-item-cinema.list-group-item-action.active {
  color: #fff;
  background-color: #5f3f5a;
  border-color: #5f3f5a;
}

.list-group-item-patrimoine {
  color: #326a3d;
  background-color: #ddefe0;
}
.list-group-item-patrimoine.list-group-item-action:hover, .list-group-item-patrimoine.list-group-item-action:focus {
  color: #326a3d;
  background-color: #c7d7ca;
}
.list-group-item-patrimoine.list-group-item-action.active {
  color: #fff;
  background-color: #326a3d;
  border-color: #326a3d;
}

.list-group-item-animation {
  color: #994531;
  background-color: #ffe3dc;
}
.list-group-item-animation.list-group-item-action:hover, .list-group-item-animation.list-group-item-action:focus {
  color: #994531;
  background-color: #e6ccc6;
}
.list-group-item-animation.list-group-item-action.active {
  color: #fff;
  background-color: #994531;
  border-color: #994531;
}

.list-group-item-education {
  color: #356360;
  background-color: #deedec;
}
.list-group-item-education.list-group-item-action:hover, .list-group-item-education.list-group-item-action:focus {
  color: #356360;
  background-color: #c8d5d4;
}
.list-group-item-education.list-group-item-action.active {
  color: #fff;
  background-color: #356360;
  border-color: #356360;
}

.list-group-item-agenda {
  color: #304362;
  background-color: #dce2ed;
}
.list-group-item-agenda.list-group-item-action:hover, .list-group-item-agenda.list-group-item-action:focus {
  color: #304362;
  background-color: #c6cbd5;
}
.list-group-item-agenda.list-group-item-action.active {
  color: #fff;
  background-color: #304362;
  border-color: #304362;
}

.list-group-item-livre {
  color: #772b3d;
  background-color: #f4dae0;
}
.list-group-item-livre.list-group-item-action:hover, .list-group-item-livre.list-group-item-action:focus {
  color: #772b3d;
  background-color: #dcc4ca;
}
.list-group-item-livre.list-group-item-action.active {
  color: #fff;
  background-color: #772b3d;
  border-color: #772b3d;
}

.list-group-item-pro {
  color: #665f00;
  background-color: #fffccc;
}
.list-group-item-pro.list-group-item-action:hover, .list-group-item-pro.list-group-item-action:focus {
  color: #665f00;
  background-color: #e6e3b8;
}
.list-group-item-pro.list-group-item-action.active {
  color: #fff;
  background-color: #665f00;
  border-color: #665f00;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(18, 18, 18, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: "PTRoot", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #121212;
}
.link-primary:hover, .link-primary:focus {
  color: #0e0e0e;
}

.link-secondary {
  color: #6c757d;
}
.link-secondary:hover, .link-secondary:focus {
  color: #565e64;
}

.link-success {
  color: #53b166;
}
.link-success:hover, .link-success:focus {
  color: #75c185;
}

.link-info {
  color: #3f69b2;
}
.link-info:hover, .link-info:focus {
  color: #32548e;
}

.link-warning {
  color: #ff9761;
}
.link-warning:hover, .link-warning:focus {
  color: #ffac81;
}

.link-danger {
  color: #fc3f59;
}
.link-danger:hover, .link-danger:focus {
  color: #fd657a;
}

.link-light {
  color: #e9ecef;
}
.link-light:hover, .link-light:focus {
  color: #edf0f2;
}

.link-dark {
  color: #000;
}
.link-dark:hover, .link-dark:focus {
  color: black;
}

.link-white {
  color: #fff;
}
.link-white:hover, .link-white:focus {
  color: white;
}

.link-upopi {
  color: #16aee5;
}
.link-upopi:hover, .link-upopi:focus {
  color: #45beea;
}

.link-cinema {
  color: #9e6996;
}
.link-cinema:hover, .link-cinema:focus {
  color: #b187ab;
}

.link-patrimoine {
  color: #53b166;
}
.link-patrimoine:hover, .link-patrimoine:focus {
  color: #75c185;
}

.link-animation {
  color: #ff7352;
}
.link-animation:hover, .link-animation:focus {
  color: #ff8f75;
}

.link-education {
  color: #59a5a0;
}
.link-education:hover, .link-education:focus {
  color: #7ab7b3;
}

.link-agenda {
  color: #506fa4;
}
.link-agenda:hover, .link-agenda:focus {
  color: #405983;
}

.link-livre {
  color: #c64766;
}
.link-livre:hover, .link-livre:focus {
  color: #9e3952;
}

.link-pro {
  color: #ffee00;
}
.link-pro:hover, .link-pro:focus {
  color: #fff133;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1700px) {
  .sticky-3xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 2000px) {
  .sticky-4xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.1;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
}

.shadow-sm {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
}

.shadow-lg {
  box-shadow: 0 15px 50px -10px rgba(0, 0, 0, 0.25) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #dee2e6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #dee2e6 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #121212 !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #53b166 !important;
}

.border-info {
  border-color: #3f69b2 !important;
}

.border-warning {
  border-color: #ff9761 !important;
}

.border-danger {
  border-color: #fc3f59 !important;
}

.border-light {
  border-color: #e9ecef !important;
}

.border-dark {
  border-color: #000 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-upopi {
  border-color: #16aee5 !important;
}

.border-cinema {
  border-color: #9e6996 !important;
}

.border-patrimoine {
  border-color: #53b166 !important;
}

.border-animation {
  border-color: #ff7352 !important;
}

.border-education {
  border-color: #59a5a0 !important;
}

.border-agenda {
  border-color: #506fa4 !important;
}

.border-livre {
  border-color: #c64766 !important;
}

.border-pro {
  border-color: #ffee00 !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 2rem !important;
}

.gap-6 {
  gap: 3rem !important;
}

.gap-7 {
  gap: 4.5rem !important;
}

.gap-8 {
  gap: 6rem !important;
}

.gap-9 {
  gap: 8rem !important;
}

.gap-10 {
  gap: 10rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 2rem !important;
}

.m-6 {
  margin: 3rem !important;
}

.m-7 {
  margin: 4.5rem !important;
}

.m-8 {
  margin: 6rem !important;
}

.m-9 {
  margin: 8rem !important;
}

.m-10 {
  margin: 10rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-6 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-7 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important;
}

.mx-8 {
  margin-right: 6rem !important;
  margin-left: 6rem !important;
}

.mx-9 {
  margin-right: 8rem !important;
  margin-left: 8rem !important;
}

.mx-10 {
  margin-right: 10rem !important;
  margin-left: 10rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-6 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-7 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.my-8 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.my-9 {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.my-10 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 2rem !important;
}

.mt-6 {
  margin-top: 3rem !important;
}

.mt-7 {
  margin-top: 4.5rem !important;
}

.mt-8 {
  margin-top: 6rem !important;
}

.mt-9 {
  margin-top: 8rem !important;
}

.mt-10 {
  margin-top: 10rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 2rem !important;
}

.me-6 {
  margin-right: 3rem !important;
}

.me-7 {
  margin-right: 4.5rem !important;
}

.me-8 {
  margin-right: 6rem !important;
}

.me-9 {
  margin-right: 8rem !important;
}

.me-10 {
  margin-right: 10rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 2rem !important;
}

.mb-6 {
  margin-bottom: 3rem !important;
}

.mb-7 {
  margin-bottom: 4.5rem !important;
}

.mb-8 {
  margin-bottom: 6rem !important;
}

.mb-9 {
  margin-bottom: 8rem !important;
}

.mb-10 {
  margin-bottom: 10rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 2rem !important;
}

.ms-6 {
  margin-left: 3rem !important;
}

.ms-7 {
  margin-left: 4.5rem !important;
}

.ms-8 {
  margin-left: 6rem !important;
}

.ms-9 {
  margin-left: 8rem !important;
}

.ms-10 {
  margin-left: 10rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -2rem !important;
}

.m-n6 {
  margin: -3rem !important;
}

.m-n7 {
  margin: -4.5rem !important;
}

.m-n8 {
  margin: -6rem !important;
}

.m-n9 {
  margin: -8rem !important;
}

.m-n10 {
  margin: -10rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -2rem !important;
  margin-left: -2rem !important;
}

.mx-n6 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.mx-n7 {
  margin-right: -4.5rem !important;
  margin-left: -4.5rem !important;
}

.mx-n8 {
  margin-right: -6rem !important;
  margin-left: -6rem !important;
}

.mx-n9 {
  margin-right: -8rem !important;
  margin-left: -8rem !important;
}

.mx-n10 {
  margin-right: -10rem !important;
  margin-left: -10rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.my-n6 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n7 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important;
}

.my-n8 {
  margin-top: -6rem !important;
  margin-bottom: -6rem !important;
}

.my-n9 {
  margin-top: -8rem !important;
  margin-bottom: -8rem !important;
}

.my-n10 {
  margin-top: -10rem !important;
  margin-bottom: -10rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -2rem !important;
}

.mt-n6 {
  margin-top: -3rem !important;
}

.mt-n7 {
  margin-top: -4.5rem !important;
}

.mt-n8 {
  margin-top: -6rem !important;
}

.mt-n9 {
  margin-top: -8rem !important;
}

.mt-n10 {
  margin-top: -10rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -2rem !important;
}

.me-n6 {
  margin-right: -3rem !important;
}

.me-n7 {
  margin-right: -4.5rem !important;
}

.me-n8 {
  margin-right: -6rem !important;
}

.me-n9 {
  margin-right: -8rem !important;
}

.me-n10 {
  margin-right: -10rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -2rem !important;
}

.mb-n6 {
  margin-bottom: -3rem !important;
}

.mb-n7 {
  margin-bottom: -4.5rem !important;
}

.mb-n8 {
  margin-bottom: -6rem !important;
}

.mb-n9 {
  margin-bottom: -8rem !important;
}

.mb-n10 {
  margin-bottom: -10rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -2rem !important;
}

.ms-n6 {
  margin-left: -3rem !important;
}

.ms-n7 {
  margin-left: -4.5rem !important;
}

.ms-n8 {
  margin-left: -6rem !important;
}

.ms-n9 {
  margin-left: -8rem !important;
}

.ms-n10 {
  margin-left: -10rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 2rem !important;
}

.p-6 {
  padding: 3rem !important;
}

.p-7 {
  padding: 4.5rem !important;
}

.p-8 {
  padding: 6rem !important;
}

.p-9 {
  padding: 8rem !important;
}

.p-10 {
  padding: 10rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-6 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-7 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important;
}

.px-8 {
  padding-right: 6rem !important;
  padding-left: 6rem !important;
}

.px-9 {
  padding-right: 8rem !important;
  padding-left: 8rem !important;
}

.px-10 {
  padding-right: 10rem !important;
  padding-left: 10rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-6 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-7 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.py-8 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.py-9 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.py-10 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 2rem !important;
}

.pt-6 {
  padding-top: 3rem !important;
}

.pt-7 {
  padding-top: 4.5rem !important;
}

.pt-8 {
  padding-top: 6rem !important;
}

.pt-9 {
  padding-top: 8rem !important;
}

.pt-10 {
  padding-top: 10rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 2rem !important;
}

.pe-6 {
  padding-right: 3rem !important;
}

.pe-7 {
  padding-right: 4.5rem !important;
}

.pe-8 {
  padding-right: 6rem !important;
}

.pe-9 {
  padding-right: 8rem !important;
}

.pe-10 {
  padding-right: 10rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 2rem !important;
}

.pb-6 {
  padding-bottom: 3rem !important;
}

.pb-7 {
  padding-bottom: 4.5rem !important;
}

.pb-8 {
  padding-bottom: 6rem !important;
}

.pb-9 {
  padding-bottom: 8rem !important;
}

.pb-10 {
  padding-bottom: 10rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 2rem !important;
}

.ps-6 {
  padding-left: 3rem !important;
}

.ps-7 {
  padding-left: 4.5rem !important;
}

.ps-8 {
  padding-left: 6rem !important;
}

.ps-9 {
  padding-left: 8rem !important;
}

.ps-10 {
  padding-left: 10rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.35rem + 1.2vw) !important;
}

.fs-2 {
  font-size: calc(1.31rem + 0.72vw) !important;
}

.fs-3 {
  font-size: calc(1.285rem + 0.42vw) !important;
}

.fs-4 {
  font-size: calc(1.26rem + 0.12vw) !important;
}

.fs-5 {
  font-size: 1.2rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-upopi {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-upopi-rgb), var(--bs-text-opacity)) !important;
}

.text-cinema {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-cinema-rgb), var(--bs-text-opacity)) !important;
}

.text-patrimoine {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-patrimoine-rgb), var(--bs-text-opacity)) !important;
}

.text-animation {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-animation-rgb), var(--bs-text-opacity)) !important;
}

.text-education {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-education-rgb), var(--bs-text-opacity)) !important;
}

.text-agenda {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-agenda-rgb), var(--bs-text-opacity)) !important;
}

.text-livre {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-livre-rgb), var(--bs-text-opacity)) !important;
}

.text-pro {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-pro-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-upopi {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-upopi-rgb), var(--bs-bg-opacity)) !important;
}

.bg-cinema {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-cinema-rgb), var(--bs-bg-opacity)) !important;
}

.bg-patrimoine {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-patrimoine-rgb), var(--bs-bg-opacity)) !important;
}

.bg-animation {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-animation-rgb), var(--bs-bg-opacity)) !important;
}

.bg-education {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-education-rgb), var(--bs-bg-opacity)) !important;
}

.bg-agenda {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-agenda-rgb), var(--bs-bg-opacity)) !important;
}

.bg-livre {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-livre-rgb), var(--bs-bg-opacity)) !important;
}

.bg-pro {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-pro-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 0.5rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.text-max-width-0 {
  max-width: 0ch !important;
}

.text-max-width-10 {
  max-width: 10ch !important;
}

.text-max-width-20 {
  max-width: 20ch !important;
}

.text-max-width-30 {
  max-width: 30ch !important;
}

.text-max-width-40 {
  max-width: 40ch !important;
}

.text-max-width-50 {
  max-width: 50ch !important;
}

.text-max-width-60 {
  max-width: 60ch !important;
}

.text-max-width-70 {
  max-width: 70ch !important;
}

.text-max-width-80 {
  max-width: 80ch !important;
}

.text-max-width-90 {
  max-width: 90ch !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 2rem !important;
  }

  .gap-sm-6 {
    gap: 3rem !important;
  }

  .gap-sm-7 {
    gap: 4.5rem !important;
  }

  .gap-sm-8 {
    gap: 6rem !important;
  }

  .gap-sm-9 {
    gap: 8rem !important;
  }

  .gap-sm-10 {
    gap: 10rem !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 2rem !important;
  }

  .m-sm-6 {
    margin: 3rem !important;
  }

  .m-sm-7 {
    margin: 4.5rem !important;
  }

  .m-sm-8 {
    margin: 6rem !important;
  }

  .m-sm-9 {
    margin: 8rem !important;
  }

  .m-sm-10 {
    margin: 10rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-sm-6 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-7 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-sm-8 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-sm-9 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-sm-10 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-sm-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-7 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-sm-8 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-sm-9 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-sm-10 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 2rem !important;
  }

  .mt-sm-6 {
    margin-top: 3rem !important;
  }

  .mt-sm-7 {
    margin-top: 4.5rem !important;
  }

  .mt-sm-8 {
    margin-top: 6rem !important;
  }

  .mt-sm-9 {
    margin-top: 8rem !important;
  }

  .mt-sm-10 {
    margin-top: 10rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 2rem !important;
  }

  .me-sm-6 {
    margin-right: 3rem !important;
  }

  .me-sm-7 {
    margin-right: 4.5rem !important;
  }

  .me-sm-8 {
    margin-right: 6rem !important;
  }

  .me-sm-9 {
    margin-right: 8rem !important;
  }

  .me-sm-10 {
    margin-right: 10rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 2rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 4.5rem !important;
  }

  .mb-sm-8 {
    margin-bottom: 6rem !important;
  }

  .mb-sm-9 {
    margin-bottom: 8rem !important;
  }

  .mb-sm-10 {
    margin-bottom: 10rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 2rem !important;
  }

  .ms-sm-6 {
    margin-left: 3rem !important;
  }

  .ms-sm-7 {
    margin-left: 4.5rem !important;
  }

  .ms-sm-8 {
    margin-left: 6rem !important;
  }

  .ms-sm-9 {
    margin-left: 8rem !important;
  }

  .ms-sm-10 {
    margin-left: 10rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -2rem !important;
  }

  .m-sm-n6 {
    margin: -3rem !important;
  }

  .m-sm-n7 {
    margin: -4.5rem !important;
  }

  .m-sm-n8 {
    margin: -6rem !important;
  }

  .m-sm-n9 {
    margin: -8rem !important;
  }

  .m-sm-n10 {
    margin: -10rem !important;
  }

  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-sm-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-sm-n6 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-sm-n7 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .mx-sm-n8 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-sm-n9 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .mx-sm-n10 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-sm-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-sm-n6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-sm-n7 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-sm-n8 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-sm-n9 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-sm-n10 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-sm-n3 {
    margin-top: -1rem !important;
  }

  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n5 {
    margin-top: -2rem !important;
  }

  .mt-sm-n6 {
    margin-top: -3rem !important;
  }

  .mt-sm-n7 {
    margin-top: -4.5rem !important;
  }

  .mt-sm-n8 {
    margin-top: -6rem !important;
  }

  .mt-sm-n9 {
    margin-top: -8rem !important;
  }

  .mt-sm-n10 {
    margin-top: -10rem !important;
  }

  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .me-sm-n3 {
    margin-right: -1rem !important;
  }

  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .me-sm-n5 {
    margin-right: -2rem !important;
  }

  .me-sm-n6 {
    margin-right: -3rem !important;
  }

  .me-sm-n7 {
    margin-right: -4.5rem !important;
  }

  .me-sm-n8 {
    margin-right: -6rem !important;
  }

  .me-sm-n9 {
    margin-right: -8rem !important;
  }

  .me-sm-n10 {
    margin-right: -10rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -2rem !important;
  }

  .mb-sm-n6 {
    margin-bottom: -3rem !important;
  }

  .mb-sm-n7 {
    margin-bottom: -4.5rem !important;
  }

  .mb-sm-n8 {
    margin-bottom: -6rem !important;
  }

  .mb-sm-n9 {
    margin-bottom: -8rem !important;
  }

  .mb-sm-n10 {
    margin-bottom: -10rem !important;
  }

  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-sm-n3 {
    margin-left: -1rem !important;
  }

  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-sm-n5 {
    margin-left: -2rem !important;
  }

  .ms-sm-n6 {
    margin-left: -3rem !important;
  }

  .ms-sm-n7 {
    margin-left: -4.5rem !important;
  }

  .ms-sm-n8 {
    margin-left: -6rem !important;
  }

  .ms-sm-n9 {
    margin-left: -8rem !important;
  }

  .ms-sm-n10 {
    margin-left: -10rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 2rem !important;
  }

  .p-sm-6 {
    padding: 3rem !important;
  }

  .p-sm-7 {
    padding: 4.5rem !important;
  }

  .p-sm-8 {
    padding: 6rem !important;
  }

  .p-sm-9 {
    padding: 8rem !important;
  }

  .p-sm-10 {
    padding: 10rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-sm-6 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-sm-7 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-sm-8 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-sm-9 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-sm-10 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-sm-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-sm-7 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-sm-8 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-sm-9 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-sm-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 2rem !important;
  }

  .pt-sm-6 {
    padding-top: 3rem !important;
  }

  .pt-sm-7 {
    padding-top: 4.5rem !important;
  }

  .pt-sm-8 {
    padding-top: 6rem !important;
  }

  .pt-sm-9 {
    padding-top: 8rem !important;
  }

  .pt-sm-10 {
    padding-top: 10rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 2rem !important;
  }

  .pe-sm-6 {
    padding-right: 3rem !important;
  }

  .pe-sm-7 {
    padding-right: 4.5rem !important;
  }

  .pe-sm-8 {
    padding-right: 6rem !important;
  }

  .pe-sm-9 {
    padding-right: 8rem !important;
  }

  .pe-sm-10 {
    padding-right: 10rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 2rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 3rem !important;
  }

  .pb-sm-7 {
    padding-bottom: 4.5rem !important;
  }

  .pb-sm-8 {
    padding-bottom: 6rem !important;
  }

  .pb-sm-9 {
    padding-bottom: 8rem !important;
  }

  .pb-sm-10 {
    padding-bottom: 10rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 2rem !important;
  }

  .ps-sm-6 {
    padding-left: 3rem !important;
  }

  .ps-sm-7 {
    padding-left: 4.5rem !important;
  }

  .ps-sm-8 {
    padding-left: 6rem !important;
  }

  .ps-sm-9 {
    padding-left: 8rem !important;
  }

  .ps-sm-10 {
    padding-left: 10rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }

  .text-max-width-sm-0 {
    max-width: 0ch !important;
  }

  .text-max-width-sm-10 {
    max-width: 10ch !important;
  }

  .text-max-width-sm-20 {
    max-width: 20ch !important;
  }

  .text-max-width-sm-30 {
    max-width: 30ch !important;
  }

  .text-max-width-sm-40 {
    max-width: 40ch !important;
  }

  .text-max-width-sm-50 {
    max-width: 50ch !important;
  }

  .text-max-width-sm-60 {
    max-width: 60ch !important;
  }

  .text-max-width-sm-70 {
    max-width: 70ch !important;
  }

  .text-max-width-sm-80 {
    max-width: 80ch !important;
  }

  .text-max-width-sm-90 {
    max-width: 90ch !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 2rem !important;
  }

  .gap-md-6 {
    gap: 3rem !important;
  }

  .gap-md-7 {
    gap: 4.5rem !important;
  }

  .gap-md-8 {
    gap: 6rem !important;
  }

  .gap-md-9 {
    gap: 8rem !important;
  }

  .gap-md-10 {
    gap: 10rem !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 2rem !important;
  }

  .m-md-6 {
    margin: 3rem !important;
  }

  .m-md-7 {
    margin: 4.5rem !important;
  }

  .m-md-8 {
    margin: 6rem !important;
  }

  .m-md-9 {
    margin: 8rem !important;
  }

  .m-md-10 {
    margin: 10rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-md-6 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-7 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-md-8 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-md-9 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-md-10 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-md-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-7 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-md-8 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-md-9 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-md-10 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 2rem !important;
  }

  .mt-md-6 {
    margin-top: 3rem !important;
  }

  .mt-md-7 {
    margin-top: 4.5rem !important;
  }

  .mt-md-8 {
    margin-top: 6rem !important;
  }

  .mt-md-9 {
    margin-top: 8rem !important;
  }

  .mt-md-10 {
    margin-top: 10rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 2rem !important;
  }

  .me-md-6 {
    margin-right: 3rem !important;
  }

  .me-md-7 {
    margin-right: 4.5rem !important;
  }

  .me-md-8 {
    margin-right: 6rem !important;
  }

  .me-md-9 {
    margin-right: 8rem !important;
  }

  .me-md-10 {
    margin-right: 10rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 2rem !important;
  }

  .mb-md-6 {
    margin-bottom: 3rem !important;
  }

  .mb-md-7 {
    margin-bottom: 4.5rem !important;
  }

  .mb-md-8 {
    margin-bottom: 6rem !important;
  }

  .mb-md-9 {
    margin-bottom: 8rem !important;
  }

  .mb-md-10 {
    margin-bottom: 10rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 2rem !important;
  }

  .ms-md-6 {
    margin-left: 3rem !important;
  }

  .ms-md-7 {
    margin-left: 4.5rem !important;
  }

  .ms-md-8 {
    margin-left: 6rem !important;
  }

  .ms-md-9 {
    margin-left: 8rem !important;
  }

  .ms-md-10 {
    margin-left: 10rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -2rem !important;
  }

  .m-md-n6 {
    margin: -3rem !important;
  }

  .m-md-n7 {
    margin: -4.5rem !important;
  }

  .m-md-n8 {
    margin: -6rem !important;
  }

  .m-md-n9 {
    margin: -8rem !important;
  }

  .m-md-n10 {
    margin: -10rem !important;
  }

  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-md-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-md-n6 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-md-n7 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .mx-md-n8 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-md-n9 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .mx-md-n10 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-md-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-md-n6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-md-n7 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-md-n8 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-md-n9 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-md-n10 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-md-n3 {
    margin-top: -1rem !important;
  }

  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n5 {
    margin-top: -2rem !important;
  }

  .mt-md-n6 {
    margin-top: -3rem !important;
  }

  .mt-md-n7 {
    margin-top: -4.5rem !important;
  }

  .mt-md-n8 {
    margin-top: -6rem !important;
  }

  .mt-md-n9 {
    margin-top: -8rem !important;
  }

  .mt-md-n10 {
    margin-top: -10rem !important;
  }

  .me-md-n1 {
    margin-right: -0.25rem !important;
  }

  .me-md-n2 {
    margin-right: -0.5rem !important;
  }

  .me-md-n3 {
    margin-right: -1rem !important;
  }

  .me-md-n4 {
    margin-right: -1.5rem !important;
  }

  .me-md-n5 {
    margin-right: -2rem !important;
  }

  .me-md-n6 {
    margin-right: -3rem !important;
  }

  .me-md-n7 {
    margin-right: -4.5rem !important;
  }

  .me-md-n8 {
    margin-right: -6rem !important;
  }

  .me-md-n9 {
    margin-right: -8rem !important;
  }

  .me-md-n10 {
    margin-right: -10rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -2rem !important;
  }

  .mb-md-n6 {
    margin-bottom: -3rem !important;
  }

  .mb-md-n7 {
    margin-bottom: -4.5rem !important;
  }

  .mb-md-n8 {
    margin-bottom: -6rem !important;
  }

  .mb-md-n9 {
    margin-bottom: -8rem !important;
  }

  .mb-md-n10 {
    margin-bottom: -10rem !important;
  }

  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-md-n3 {
    margin-left: -1rem !important;
  }

  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-md-n5 {
    margin-left: -2rem !important;
  }

  .ms-md-n6 {
    margin-left: -3rem !important;
  }

  .ms-md-n7 {
    margin-left: -4.5rem !important;
  }

  .ms-md-n8 {
    margin-left: -6rem !important;
  }

  .ms-md-n9 {
    margin-left: -8rem !important;
  }

  .ms-md-n10 {
    margin-left: -10rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 2rem !important;
  }

  .p-md-6 {
    padding: 3rem !important;
  }

  .p-md-7 {
    padding: 4.5rem !important;
  }

  .p-md-8 {
    padding: 6rem !important;
  }

  .p-md-9 {
    padding: 8rem !important;
  }

  .p-md-10 {
    padding: 10rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-md-6 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-md-7 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-md-8 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-md-9 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-md-10 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-md-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-md-7 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-md-8 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-md-9 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-md-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 2rem !important;
  }

  .pt-md-6 {
    padding-top: 3rem !important;
  }

  .pt-md-7 {
    padding-top: 4.5rem !important;
  }

  .pt-md-8 {
    padding-top: 6rem !important;
  }

  .pt-md-9 {
    padding-top: 8rem !important;
  }

  .pt-md-10 {
    padding-top: 10rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 2rem !important;
  }

  .pe-md-6 {
    padding-right: 3rem !important;
  }

  .pe-md-7 {
    padding-right: 4.5rem !important;
  }

  .pe-md-8 {
    padding-right: 6rem !important;
  }

  .pe-md-9 {
    padding-right: 8rem !important;
  }

  .pe-md-10 {
    padding-right: 10rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 2rem !important;
  }

  .pb-md-6 {
    padding-bottom: 3rem !important;
  }

  .pb-md-7 {
    padding-bottom: 4.5rem !important;
  }

  .pb-md-8 {
    padding-bottom: 6rem !important;
  }

  .pb-md-9 {
    padding-bottom: 8rem !important;
  }

  .pb-md-10 {
    padding-bottom: 10rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 2rem !important;
  }

  .ps-md-6 {
    padding-left: 3rem !important;
  }

  .ps-md-7 {
    padding-left: 4.5rem !important;
  }

  .ps-md-8 {
    padding-left: 6rem !important;
  }

  .ps-md-9 {
    padding-left: 8rem !important;
  }

  .ps-md-10 {
    padding-left: 10rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }

  .text-max-width-md-0 {
    max-width: 0ch !important;
  }

  .text-max-width-md-10 {
    max-width: 10ch !important;
  }

  .text-max-width-md-20 {
    max-width: 20ch !important;
  }

  .text-max-width-md-30 {
    max-width: 30ch !important;
  }

  .text-max-width-md-40 {
    max-width: 40ch !important;
  }

  .text-max-width-md-50 {
    max-width: 50ch !important;
  }

  .text-max-width-md-60 {
    max-width: 60ch !important;
  }

  .text-max-width-md-70 {
    max-width: 70ch !important;
  }

  .text-max-width-md-80 {
    max-width: 80ch !important;
  }

  .text-max-width-md-90 {
    max-width: 90ch !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 2rem !important;
  }

  .gap-lg-6 {
    gap: 3rem !important;
  }

  .gap-lg-7 {
    gap: 4.5rem !important;
  }

  .gap-lg-8 {
    gap: 6rem !important;
  }

  .gap-lg-9 {
    gap: 8rem !important;
  }

  .gap-lg-10 {
    gap: 10rem !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 2rem !important;
  }

  .m-lg-6 {
    margin: 3rem !important;
  }

  .m-lg-7 {
    margin: 4.5rem !important;
  }

  .m-lg-8 {
    margin: 6rem !important;
  }

  .m-lg-9 {
    margin: 8rem !important;
  }

  .m-lg-10 {
    margin: 10rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-lg-6 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-7 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-lg-8 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-lg-9 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-lg-10 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-lg-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-7 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-lg-8 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-lg-9 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-lg-10 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 2rem !important;
  }

  .mt-lg-6 {
    margin-top: 3rem !important;
  }

  .mt-lg-7 {
    margin-top: 4.5rem !important;
  }

  .mt-lg-8 {
    margin-top: 6rem !important;
  }

  .mt-lg-9 {
    margin-top: 8rem !important;
  }

  .mt-lg-10 {
    margin-top: 10rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 2rem !important;
  }

  .me-lg-6 {
    margin-right: 3rem !important;
  }

  .me-lg-7 {
    margin-right: 4.5rem !important;
  }

  .me-lg-8 {
    margin-right: 6rem !important;
  }

  .me-lg-9 {
    margin-right: 8rem !important;
  }

  .me-lg-10 {
    margin-right: 10rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 2rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 4.5rem !important;
  }

  .mb-lg-8 {
    margin-bottom: 6rem !important;
  }

  .mb-lg-9 {
    margin-bottom: 8rem !important;
  }

  .mb-lg-10 {
    margin-bottom: 10rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 2rem !important;
  }

  .ms-lg-6 {
    margin-left: 3rem !important;
  }

  .ms-lg-7 {
    margin-left: 4.5rem !important;
  }

  .ms-lg-8 {
    margin-left: 6rem !important;
  }

  .ms-lg-9 {
    margin-left: 8rem !important;
  }

  .ms-lg-10 {
    margin-left: 10rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -2rem !important;
  }

  .m-lg-n6 {
    margin: -3rem !important;
  }

  .m-lg-n7 {
    margin: -4.5rem !important;
  }

  .m-lg-n8 {
    margin: -6rem !important;
  }

  .m-lg-n9 {
    margin: -8rem !important;
  }

  .m-lg-n10 {
    margin: -10rem !important;
  }

  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-lg-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-lg-n6 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-lg-n7 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .mx-lg-n8 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-lg-n9 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .mx-lg-n10 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lg-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-lg-n6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-lg-n7 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-lg-n8 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-lg-n9 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-lg-n10 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-lg-n3 {
    margin-top: -1rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n5 {
    margin-top: -2rem !important;
  }

  .mt-lg-n6 {
    margin-top: -3rem !important;
  }

  .mt-lg-n7 {
    margin-top: -4.5rem !important;
  }

  .mt-lg-n8 {
    margin-top: -6rem !important;
  }

  .mt-lg-n9 {
    margin-top: -8rem !important;
  }

  .mt-lg-n10 {
    margin-top: -10rem !important;
  }

  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .me-lg-n3 {
    margin-right: -1rem !important;
  }

  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .me-lg-n5 {
    margin-right: -2rem !important;
  }

  .me-lg-n6 {
    margin-right: -3rem !important;
  }

  .me-lg-n7 {
    margin-right: -4.5rem !important;
  }

  .me-lg-n8 {
    margin-right: -6rem !important;
  }

  .me-lg-n9 {
    margin-right: -8rem !important;
  }

  .me-lg-n10 {
    margin-right: -10rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -2rem !important;
  }

  .mb-lg-n6 {
    margin-bottom: -3rem !important;
  }

  .mb-lg-n7 {
    margin-bottom: -4.5rem !important;
  }

  .mb-lg-n8 {
    margin-bottom: -6rem !important;
  }

  .mb-lg-n9 {
    margin-bottom: -8rem !important;
  }

  .mb-lg-n10 {
    margin-bottom: -10rem !important;
  }

  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-lg-n3 {
    margin-left: -1rem !important;
  }

  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-lg-n5 {
    margin-left: -2rem !important;
  }

  .ms-lg-n6 {
    margin-left: -3rem !important;
  }

  .ms-lg-n7 {
    margin-left: -4.5rem !important;
  }

  .ms-lg-n8 {
    margin-left: -6rem !important;
  }

  .ms-lg-n9 {
    margin-left: -8rem !important;
  }

  .ms-lg-n10 {
    margin-left: -10rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 2rem !important;
  }

  .p-lg-6 {
    padding: 3rem !important;
  }

  .p-lg-7 {
    padding: 4.5rem !important;
  }

  .p-lg-8 {
    padding: 6rem !important;
  }

  .p-lg-9 {
    padding: 8rem !important;
  }

  .p-lg-10 {
    padding: 10rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-lg-6 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-lg-7 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-lg-8 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-lg-9 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-lg-10 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-lg-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-lg-7 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-lg-8 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-lg-9 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-lg-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 2rem !important;
  }

  .pt-lg-6 {
    padding-top: 3rem !important;
  }

  .pt-lg-7 {
    padding-top: 4.5rem !important;
  }

  .pt-lg-8 {
    padding-top: 6rem !important;
  }

  .pt-lg-9 {
    padding-top: 8rem !important;
  }

  .pt-lg-10 {
    padding-top: 10rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 2rem !important;
  }

  .pe-lg-6 {
    padding-right: 3rem !important;
  }

  .pe-lg-7 {
    padding-right: 4.5rem !important;
  }

  .pe-lg-8 {
    padding-right: 6rem !important;
  }

  .pe-lg-9 {
    padding-right: 8rem !important;
  }

  .pe-lg-10 {
    padding-right: 10rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 2rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 3rem !important;
  }

  .pb-lg-7 {
    padding-bottom: 4.5rem !important;
  }

  .pb-lg-8 {
    padding-bottom: 6rem !important;
  }

  .pb-lg-9 {
    padding-bottom: 8rem !important;
  }

  .pb-lg-10 {
    padding-bottom: 10rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 2rem !important;
  }

  .ps-lg-6 {
    padding-left: 3rem !important;
  }

  .ps-lg-7 {
    padding-left: 4.5rem !important;
  }

  .ps-lg-8 {
    padding-left: 6rem !important;
  }

  .ps-lg-9 {
    padding-left: 8rem !important;
  }

  .ps-lg-10 {
    padding-left: 10rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }

  .text-max-width-lg-0 {
    max-width: 0ch !important;
  }

  .text-max-width-lg-10 {
    max-width: 10ch !important;
  }

  .text-max-width-lg-20 {
    max-width: 20ch !important;
  }

  .text-max-width-lg-30 {
    max-width: 30ch !important;
  }

  .text-max-width-lg-40 {
    max-width: 40ch !important;
  }

  .text-max-width-lg-50 {
    max-width: 50ch !important;
  }

  .text-max-width-lg-60 {
    max-width: 60ch !important;
  }

  .text-max-width-lg-70 {
    max-width: 70ch !important;
  }

  .text-max-width-lg-80 {
    max-width: 80ch !important;
  }

  .text-max-width-lg-90 {
    max-width: 90ch !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 2rem !important;
  }

  .gap-xl-6 {
    gap: 3rem !important;
  }

  .gap-xl-7 {
    gap: 4.5rem !important;
  }

  .gap-xl-8 {
    gap: 6rem !important;
  }

  .gap-xl-9 {
    gap: 8rem !important;
  }

  .gap-xl-10 {
    gap: 10rem !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 2rem !important;
  }

  .m-xl-6 {
    margin: 3rem !important;
  }

  .m-xl-7 {
    margin: 4.5rem !important;
  }

  .m-xl-8 {
    margin: 6rem !important;
  }

  .m-xl-9 {
    margin: 8rem !important;
  }

  .m-xl-10 {
    margin: 10rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xl-6 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-7 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-xl-8 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-xl-9 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-xl-10 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xl-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-7 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xl-8 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-xl-9 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-xl-10 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 2rem !important;
  }

  .mt-xl-6 {
    margin-top: 3rem !important;
  }

  .mt-xl-7 {
    margin-top: 4.5rem !important;
  }

  .mt-xl-8 {
    margin-top: 6rem !important;
  }

  .mt-xl-9 {
    margin-top: 8rem !important;
  }

  .mt-xl-10 {
    margin-top: 10rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 2rem !important;
  }

  .me-xl-6 {
    margin-right: 3rem !important;
  }

  .me-xl-7 {
    margin-right: 4.5rem !important;
  }

  .me-xl-8 {
    margin-right: 6rem !important;
  }

  .me-xl-9 {
    margin-right: 8rem !important;
  }

  .me-xl-10 {
    margin-right: 10rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 2rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xl-8 {
    margin-bottom: 6rem !important;
  }

  .mb-xl-9 {
    margin-bottom: 8rem !important;
  }

  .mb-xl-10 {
    margin-bottom: 10rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 2rem !important;
  }

  .ms-xl-6 {
    margin-left: 3rem !important;
  }

  .ms-xl-7 {
    margin-left: 4.5rem !important;
  }

  .ms-xl-8 {
    margin-left: 6rem !important;
  }

  .ms-xl-9 {
    margin-left: 8rem !important;
  }

  .ms-xl-10 {
    margin-left: 10rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -2rem !important;
  }

  .m-xl-n6 {
    margin: -3rem !important;
  }

  .m-xl-n7 {
    margin: -4.5rem !important;
  }

  .m-xl-n8 {
    margin: -6rem !important;
  }

  .m-xl-n9 {
    margin: -8rem !important;
  }

  .m-xl-n10 {
    margin: -10rem !important;
  }

  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xl-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-xl-n6 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-xl-n7 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .mx-xl-n8 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-xl-n9 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .mx-xl-n10 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xl-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xl-n6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xl-n7 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-xl-n8 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-xl-n9 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-xl-n10 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n5 {
    margin-top: -2rem !important;
  }

  .mt-xl-n6 {
    margin-top: -3rem !important;
  }

  .mt-xl-n7 {
    margin-top: -4.5rem !important;
  }

  .mt-xl-n8 {
    margin-top: -6rem !important;
  }

  .mt-xl-n9 {
    margin-top: -8rem !important;
  }

  .mt-xl-n10 {
    margin-top: -10rem !important;
  }

  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xl-n3 {
    margin-right: -1rem !important;
  }

  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xl-n5 {
    margin-right: -2rem !important;
  }

  .me-xl-n6 {
    margin-right: -3rem !important;
  }

  .me-xl-n7 {
    margin-right: -4.5rem !important;
  }

  .me-xl-n8 {
    margin-right: -6rem !important;
  }

  .me-xl-n9 {
    margin-right: -8rem !important;
  }

  .me-xl-n10 {
    margin-right: -10rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -2rem !important;
  }

  .mb-xl-n6 {
    margin-bottom: -3rem !important;
  }

  .mb-xl-n7 {
    margin-bottom: -4.5rem !important;
  }

  .mb-xl-n8 {
    margin-bottom: -6rem !important;
  }

  .mb-xl-n9 {
    margin-bottom: -8rem !important;
  }

  .mb-xl-n10 {
    margin-bottom: -10rem !important;
  }

  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xl-n5 {
    margin-left: -2rem !important;
  }

  .ms-xl-n6 {
    margin-left: -3rem !important;
  }

  .ms-xl-n7 {
    margin-left: -4.5rem !important;
  }

  .ms-xl-n8 {
    margin-left: -6rem !important;
  }

  .ms-xl-n9 {
    margin-left: -8rem !important;
  }

  .ms-xl-n10 {
    margin-left: -10rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 2rem !important;
  }

  .p-xl-6 {
    padding: 3rem !important;
  }

  .p-xl-7 {
    padding: 4.5rem !important;
  }

  .p-xl-8 {
    padding: 6rem !important;
  }

  .p-xl-9 {
    padding: 8rem !important;
  }

  .p-xl-10 {
    padding: 10rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xl-6 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xl-7 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-xl-8 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-xl-9 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-xl-10 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xl-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xl-7 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-xl-8 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-xl-9 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-xl-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 2rem !important;
  }

  .pt-xl-6 {
    padding-top: 3rem !important;
  }

  .pt-xl-7 {
    padding-top: 4.5rem !important;
  }

  .pt-xl-8 {
    padding-top: 6rem !important;
  }

  .pt-xl-9 {
    padding-top: 8rem !important;
  }

  .pt-xl-10 {
    padding-top: 10rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 2rem !important;
  }

  .pe-xl-6 {
    padding-right: 3rem !important;
  }

  .pe-xl-7 {
    padding-right: 4.5rem !important;
  }

  .pe-xl-8 {
    padding-right: 6rem !important;
  }

  .pe-xl-9 {
    padding-right: 8rem !important;
  }

  .pe-xl-10 {
    padding-right: 10rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 2rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 3rem !important;
  }

  .pb-xl-7 {
    padding-bottom: 4.5rem !important;
  }

  .pb-xl-8 {
    padding-bottom: 6rem !important;
  }

  .pb-xl-9 {
    padding-bottom: 8rem !important;
  }

  .pb-xl-10 {
    padding-bottom: 10rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 2rem !important;
  }

  .ps-xl-6 {
    padding-left: 3rem !important;
  }

  .ps-xl-7 {
    padding-left: 4.5rem !important;
  }

  .ps-xl-8 {
    padding-left: 6rem !important;
  }

  .ps-xl-9 {
    padding-left: 8rem !important;
  }

  .ps-xl-10 {
    padding-left: 10rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }

  .text-max-width-xl-0 {
    max-width: 0ch !important;
  }

  .text-max-width-xl-10 {
    max-width: 10ch !important;
  }

  .text-max-width-xl-20 {
    max-width: 20ch !important;
  }

  .text-max-width-xl-30 {
    max-width: 30ch !important;
  }

  .text-max-width-xl-40 {
    max-width: 40ch !important;
  }

  .text-max-width-xl-50 {
    max-width: 50ch !important;
  }

  .text-max-width-xl-60 {
    max-width: 60ch !important;
  }

  .text-max-width-xl-70 {
    max-width: 70ch !important;
  }

  .text-max-width-xl-80 {
    max-width: 80ch !important;
  }

  .text-max-width-xl-90 {
    max-width: 90ch !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 2rem !important;
  }

  .gap-xxl-6 {
    gap: 3rem !important;
  }

  .gap-xxl-7 {
    gap: 4.5rem !important;
  }

  .gap-xxl-8 {
    gap: 6rem !important;
  }

  .gap-xxl-9 {
    gap: 8rem !important;
  }

  .gap-xxl-10 {
    gap: 10rem !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 2rem !important;
  }

  .m-xxl-6 {
    margin: 3rem !important;
  }

  .m-xxl-7 {
    margin: 4.5rem !important;
  }

  .m-xxl-8 {
    margin: 6rem !important;
  }

  .m-xxl-9 {
    margin: 8rem !important;
  }

  .m-xxl-10 {
    margin: 10rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xxl-6 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-7 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-xxl-8 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-xxl-9 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-xxl-10 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xxl-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-7 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xxl-8 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-xxl-9 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-xxl-10 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 2rem !important;
  }

  .mt-xxl-6 {
    margin-top: 3rem !important;
  }

  .mt-xxl-7 {
    margin-top: 4.5rem !important;
  }

  .mt-xxl-8 {
    margin-top: 6rem !important;
  }

  .mt-xxl-9 {
    margin-top: 8rem !important;
  }

  .mt-xxl-10 {
    margin-top: 10rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 2rem !important;
  }

  .me-xxl-6 {
    margin-right: 3rem !important;
  }

  .me-xxl-7 {
    margin-right: 4.5rem !important;
  }

  .me-xxl-8 {
    margin-right: 6rem !important;
  }

  .me-xxl-9 {
    margin-right: 8rem !important;
  }

  .me-xxl-10 {
    margin-right: 10rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 2rem !important;
  }

  .mb-xxl-6 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-7 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xxl-8 {
    margin-bottom: 6rem !important;
  }

  .mb-xxl-9 {
    margin-bottom: 8rem !important;
  }

  .mb-xxl-10 {
    margin-bottom: 10rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 2rem !important;
  }

  .ms-xxl-6 {
    margin-left: 3rem !important;
  }

  .ms-xxl-7 {
    margin-left: 4.5rem !important;
  }

  .ms-xxl-8 {
    margin-left: 6rem !important;
  }

  .ms-xxl-9 {
    margin-left: 8rem !important;
  }

  .ms-xxl-10 {
    margin-left: 10rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -1rem !important;
  }

  .m-xxl-n4 {
    margin: -1.5rem !important;
  }

  .m-xxl-n5 {
    margin: -2rem !important;
  }

  .m-xxl-n6 {
    margin: -3rem !important;
  }

  .m-xxl-n7 {
    margin: -4.5rem !important;
  }

  .m-xxl-n8 {
    margin: -6rem !important;
  }

  .m-xxl-n9 {
    margin: -8rem !important;
  }

  .m-xxl-n10 {
    margin: -10rem !important;
  }

  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xxl-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-xxl-n6 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-xxl-n7 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .mx-xxl-n8 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-xxl-n9 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .mx-xxl-n10 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xxl-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xxl-n6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xxl-n7 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-xxl-n8 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-xxl-n9 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-xxl-n10 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -2rem !important;
  }

  .mt-xxl-n6 {
    margin-top: -3rem !important;
  }

  .mt-xxl-n7 {
    margin-top: -4.5rem !important;
  }

  .mt-xxl-n8 {
    margin-top: -6rem !important;
  }

  .mt-xxl-n9 {
    margin-top: -8rem !important;
  }

  .mt-xxl-n10 {
    margin-top: -10rem !important;
  }

  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xxl-n3 {
    margin-right: -1rem !important;
  }

  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xxl-n5 {
    margin-right: -2rem !important;
  }

  .me-xxl-n6 {
    margin-right: -3rem !important;
  }

  .me-xxl-n7 {
    margin-right: -4.5rem !important;
  }

  .me-xxl-n8 {
    margin-right: -6rem !important;
  }

  .me-xxl-n9 {
    margin-right: -8rem !important;
  }

  .me-xxl-n10 {
    margin-right: -10rem !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -2rem !important;
  }

  .mb-xxl-n6 {
    margin-bottom: -3rem !important;
  }

  .mb-xxl-n7 {
    margin-bottom: -4.5rem !important;
  }

  .mb-xxl-n8 {
    margin-bottom: -6rem !important;
  }

  .mb-xxl-n9 {
    margin-bottom: -8rem !important;
  }

  .mb-xxl-n10 {
    margin-bottom: -10rem !important;
  }

  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xxl-n5 {
    margin-left: -2rem !important;
  }

  .ms-xxl-n6 {
    margin-left: -3rem !important;
  }

  .ms-xxl-n7 {
    margin-left: -4.5rem !important;
  }

  .ms-xxl-n8 {
    margin-left: -6rem !important;
  }

  .ms-xxl-n9 {
    margin-left: -8rem !important;
  }

  .ms-xxl-n10 {
    margin-left: -10rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 2rem !important;
  }

  .p-xxl-6 {
    padding: 3rem !important;
  }

  .p-xxl-7 {
    padding: 4.5rem !important;
  }

  .p-xxl-8 {
    padding: 6rem !important;
  }

  .p-xxl-9 {
    padding: 8rem !important;
  }

  .p-xxl-10 {
    padding: 10rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xxl-6 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xxl-7 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-xxl-8 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-xxl-9 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-xxl-10 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xxl-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xxl-7 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-xxl-8 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-xxl-9 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-xxl-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 2rem !important;
  }

  .pt-xxl-6 {
    padding-top: 3rem !important;
  }

  .pt-xxl-7 {
    padding-top: 4.5rem !important;
  }

  .pt-xxl-8 {
    padding-top: 6rem !important;
  }

  .pt-xxl-9 {
    padding-top: 8rem !important;
  }

  .pt-xxl-10 {
    padding-top: 10rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 2rem !important;
  }

  .pe-xxl-6 {
    padding-right: 3rem !important;
  }

  .pe-xxl-7 {
    padding-right: 4.5rem !important;
  }

  .pe-xxl-8 {
    padding-right: 6rem !important;
  }

  .pe-xxl-9 {
    padding-right: 8rem !important;
  }

  .pe-xxl-10 {
    padding-right: 10rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 2rem !important;
  }

  .pb-xxl-6 {
    padding-bottom: 3rem !important;
  }

  .pb-xxl-7 {
    padding-bottom: 4.5rem !important;
  }

  .pb-xxl-8 {
    padding-bottom: 6rem !important;
  }

  .pb-xxl-9 {
    padding-bottom: 8rem !important;
  }

  .pb-xxl-10 {
    padding-bottom: 10rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 2rem !important;
  }

  .ps-xxl-6 {
    padding-left: 3rem !important;
  }

  .ps-xxl-7 {
    padding-left: 4.5rem !important;
  }

  .ps-xxl-8 {
    padding-left: 6rem !important;
  }

  .ps-xxl-9 {
    padding-left: 8rem !important;
  }

  .ps-xxl-10 {
    padding-left: 10rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }

  .text-max-width-xxl-0 {
    max-width: 0ch !important;
  }

  .text-max-width-xxl-10 {
    max-width: 10ch !important;
  }

  .text-max-width-xxl-20 {
    max-width: 20ch !important;
  }

  .text-max-width-xxl-30 {
    max-width: 30ch !important;
  }

  .text-max-width-xxl-40 {
    max-width: 40ch !important;
  }

  .text-max-width-xxl-50 {
    max-width: 50ch !important;
  }

  .text-max-width-xxl-60 {
    max-width: 60ch !important;
  }

  .text-max-width-xxl-70 {
    max-width: 70ch !important;
  }

  .text-max-width-xxl-80 {
    max-width: 80ch !important;
  }

  .text-max-width-xxl-90 {
    max-width: 90ch !important;
  }
}
@media (min-width: 1700px) {
  .float-3xl-start {
    float: left !important;
  }

  .float-3xl-end {
    float: right !important;
  }

  .float-3xl-none {
    float: none !important;
  }

  .d-3xl-inline {
    display: inline !important;
  }

  .d-3xl-inline-block {
    display: inline-block !important;
  }

  .d-3xl-block {
    display: block !important;
  }

  .d-3xl-grid {
    display: grid !important;
  }

  .d-3xl-table {
    display: table !important;
  }

  .d-3xl-table-row {
    display: table-row !important;
  }

  .d-3xl-table-cell {
    display: table-cell !important;
  }

  .d-3xl-flex {
    display: flex !important;
  }

  .d-3xl-inline-flex {
    display: inline-flex !important;
  }

  .d-3xl-none {
    display: none !important;
  }

  .flex-3xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-3xl-row {
    flex-direction: row !important;
  }

  .flex-3xl-column {
    flex-direction: column !important;
  }

  .flex-3xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-3xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-3xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-3xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-3xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-3xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-3xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-3xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-3xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-3xl-0 {
    gap: 0 !important;
  }

  .gap-3xl-1 {
    gap: 0.25rem !important;
  }

  .gap-3xl-2 {
    gap: 0.5rem !important;
  }

  .gap-3xl-3 {
    gap: 1rem !important;
  }

  .gap-3xl-4 {
    gap: 1.5rem !important;
  }

  .gap-3xl-5 {
    gap: 2rem !important;
  }

  .gap-3xl-6 {
    gap: 3rem !important;
  }

  .gap-3xl-7 {
    gap: 4.5rem !important;
  }

  .gap-3xl-8 {
    gap: 6rem !important;
  }

  .gap-3xl-9 {
    gap: 8rem !important;
  }

  .gap-3xl-10 {
    gap: 10rem !important;
  }

  .justify-content-3xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-3xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-3xl-center {
    justify-content: center !important;
  }

  .justify-content-3xl-between {
    justify-content: space-between !important;
  }

  .justify-content-3xl-around {
    justify-content: space-around !important;
  }

  .justify-content-3xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-3xl-start {
    align-items: flex-start !important;
  }

  .align-items-3xl-end {
    align-items: flex-end !important;
  }

  .align-items-3xl-center {
    align-items: center !important;
  }

  .align-items-3xl-baseline {
    align-items: baseline !important;
  }

  .align-items-3xl-stretch {
    align-items: stretch !important;
  }

  .align-content-3xl-start {
    align-content: flex-start !important;
  }

  .align-content-3xl-end {
    align-content: flex-end !important;
  }

  .align-content-3xl-center {
    align-content: center !important;
  }

  .align-content-3xl-between {
    align-content: space-between !important;
  }

  .align-content-3xl-around {
    align-content: space-around !important;
  }

  .align-content-3xl-stretch {
    align-content: stretch !important;
  }

  .align-self-3xl-auto {
    align-self: auto !important;
  }

  .align-self-3xl-start {
    align-self: flex-start !important;
  }

  .align-self-3xl-end {
    align-self: flex-end !important;
  }

  .align-self-3xl-center {
    align-self: center !important;
  }

  .align-self-3xl-baseline {
    align-self: baseline !important;
  }

  .align-self-3xl-stretch {
    align-self: stretch !important;
  }

  .order-3xl-first {
    order: -1 !important;
  }

  .order-3xl-0 {
    order: 0 !important;
  }

  .order-3xl-1 {
    order: 1 !important;
  }

  .order-3xl-2 {
    order: 2 !important;
  }

  .order-3xl-3 {
    order: 3 !important;
  }

  .order-3xl-4 {
    order: 4 !important;
  }

  .order-3xl-5 {
    order: 5 !important;
  }

  .order-3xl-last {
    order: 6 !important;
  }

  .m-3xl-0 {
    margin: 0 !important;
  }

  .m-3xl-1 {
    margin: 0.25rem !important;
  }

  .m-3xl-2 {
    margin: 0.5rem !important;
  }

  .m-3xl-3 {
    margin: 1rem !important;
  }

  .m-3xl-4 {
    margin: 1.5rem !important;
  }

  .m-3xl-5 {
    margin: 2rem !important;
  }

  .m-3xl-6 {
    margin: 3rem !important;
  }

  .m-3xl-7 {
    margin: 4.5rem !important;
  }

  .m-3xl-8 {
    margin: 6rem !important;
  }

  .m-3xl-9 {
    margin: 8rem !important;
  }

  .m-3xl-10 {
    margin: 10rem !important;
  }

  .m-3xl-auto {
    margin: auto !important;
  }

  .mx-3xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-3xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-3xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-3xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-3xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-3xl-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-3xl-6 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-3xl-7 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-3xl-8 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-3xl-9 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-3xl-10 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-3xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-3xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-3xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-3xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-3xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-3xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-3xl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-3xl-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-3xl-7 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-3xl-8 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-3xl-9 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-3xl-10 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-3xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-3xl-0 {
    margin-top: 0 !important;
  }

  .mt-3xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-3xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-3xl-3 {
    margin-top: 1rem !important;
  }

  .mt-3xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-3xl-5 {
    margin-top: 2rem !important;
  }

  .mt-3xl-6 {
    margin-top: 3rem !important;
  }

  .mt-3xl-7 {
    margin-top: 4.5rem !important;
  }

  .mt-3xl-8 {
    margin-top: 6rem !important;
  }

  .mt-3xl-9 {
    margin-top: 8rem !important;
  }

  .mt-3xl-10 {
    margin-top: 10rem !important;
  }

  .mt-3xl-auto {
    margin-top: auto !important;
  }

  .me-3xl-0 {
    margin-right: 0 !important;
  }

  .me-3xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-3xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-3xl-3 {
    margin-right: 1rem !important;
  }

  .me-3xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-3xl-5 {
    margin-right: 2rem !important;
  }

  .me-3xl-6 {
    margin-right: 3rem !important;
  }

  .me-3xl-7 {
    margin-right: 4.5rem !important;
  }

  .me-3xl-8 {
    margin-right: 6rem !important;
  }

  .me-3xl-9 {
    margin-right: 8rem !important;
  }

  .me-3xl-10 {
    margin-right: 10rem !important;
  }

  .me-3xl-auto {
    margin-right: auto !important;
  }

  .mb-3xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-3xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-3xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-3xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-3xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-3xl-5 {
    margin-bottom: 2rem !important;
  }

  .mb-3xl-6 {
    margin-bottom: 3rem !important;
  }

  .mb-3xl-7 {
    margin-bottom: 4.5rem !important;
  }

  .mb-3xl-8 {
    margin-bottom: 6rem !important;
  }

  .mb-3xl-9 {
    margin-bottom: 8rem !important;
  }

  .mb-3xl-10 {
    margin-bottom: 10rem !important;
  }

  .mb-3xl-auto {
    margin-bottom: auto !important;
  }

  .ms-3xl-0 {
    margin-left: 0 !important;
  }

  .ms-3xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-3xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-3xl-3 {
    margin-left: 1rem !important;
  }

  .ms-3xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-3xl-5 {
    margin-left: 2rem !important;
  }

  .ms-3xl-6 {
    margin-left: 3rem !important;
  }

  .ms-3xl-7 {
    margin-left: 4.5rem !important;
  }

  .ms-3xl-8 {
    margin-left: 6rem !important;
  }

  .ms-3xl-9 {
    margin-left: 8rem !important;
  }

  .ms-3xl-10 {
    margin-left: 10rem !important;
  }

  .ms-3xl-auto {
    margin-left: auto !important;
  }

  .m-3xl-n1 {
    margin: -0.25rem !important;
  }

  .m-3xl-n2 {
    margin: -0.5rem !important;
  }

  .m-3xl-n3 {
    margin: -1rem !important;
  }

  .m-3xl-n4 {
    margin: -1.5rem !important;
  }

  .m-3xl-n5 {
    margin: -2rem !important;
  }

  .m-3xl-n6 {
    margin: -3rem !important;
  }

  .m-3xl-n7 {
    margin: -4.5rem !important;
  }

  .m-3xl-n8 {
    margin: -6rem !important;
  }

  .m-3xl-n9 {
    margin: -8rem !important;
  }

  .m-3xl-n10 {
    margin: -10rem !important;
  }

  .mx-3xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-3xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-3xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-3xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-3xl-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-3xl-n6 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-3xl-n7 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .mx-3xl-n8 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-3xl-n9 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .mx-3xl-n10 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .my-3xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-3xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-3xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-3xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-3xl-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-3xl-n6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-3xl-n7 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-3xl-n8 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-3xl-n9 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-3xl-n10 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .mt-3xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-3xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-3xl-n3 {
    margin-top: -1rem !important;
  }

  .mt-3xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-3xl-n5 {
    margin-top: -2rem !important;
  }

  .mt-3xl-n6 {
    margin-top: -3rem !important;
  }

  .mt-3xl-n7 {
    margin-top: -4.5rem !important;
  }

  .mt-3xl-n8 {
    margin-top: -6rem !important;
  }

  .mt-3xl-n9 {
    margin-top: -8rem !important;
  }

  .mt-3xl-n10 {
    margin-top: -10rem !important;
  }

  .me-3xl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-3xl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-3xl-n3 {
    margin-right: -1rem !important;
  }

  .me-3xl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-3xl-n5 {
    margin-right: -2rem !important;
  }

  .me-3xl-n6 {
    margin-right: -3rem !important;
  }

  .me-3xl-n7 {
    margin-right: -4.5rem !important;
  }

  .me-3xl-n8 {
    margin-right: -6rem !important;
  }

  .me-3xl-n9 {
    margin-right: -8rem !important;
  }

  .me-3xl-n10 {
    margin-right: -10rem !important;
  }

  .mb-3xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-3xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-3xl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-3xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-3xl-n5 {
    margin-bottom: -2rem !important;
  }

  .mb-3xl-n6 {
    margin-bottom: -3rem !important;
  }

  .mb-3xl-n7 {
    margin-bottom: -4.5rem !important;
  }

  .mb-3xl-n8 {
    margin-bottom: -6rem !important;
  }

  .mb-3xl-n9 {
    margin-bottom: -8rem !important;
  }

  .mb-3xl-n10 {
    margin-bottom: -10rem !important;
  }

  .ms-3xl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-3xl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-3xl-n3 {
    margin-left: -1rem !important;
  }

  .ms-3xl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-3xl-n5 {
    margin-left: -2rem !important;
  }

  .ms-3xl-n6 {
    margin-left: -3rem !important;
  }

  .ms-3xl-n7 {
    margin-left: -4.5rem !important;
  }

  .ms-3xl-n8 {
    margin-left: -6rem !important;
  }

  .ms-3xl-n9 {
    margin-left: -8rem !important;
  }

  .ms-3xl-n10 {
    margin-left: -10rem !important;
  }

  .p-3xl-0 {
    padding: 0 !important;
  }

  .p-3xl-1 {
    padding: 0.25rem !important;
  }

  .p-3xl-2 {
    padding: 0.5rem !important;
  }

  .p-3xl-3 {
    padding: 1rem !important;
  }

  .p-3xl-4 {
    padding: 1.5rem !important;
  }

  .p-3xl-5 {
    padding: 2rem !important;
  }

  .p-3xl-6 {
    padding: 3rem !important;
  }

  .p-3xl-7 {
    padding: 4.5rem !important;
  }

  .p-3xl-8 {
    padding: 6rem !important;
  }

  .p-3xl-9 {
    padding: 8rem !important;
  }

  .p-3xl-10 {
    padding: 10rem !important;
  }

  .px-3xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-3xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-3xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-3xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-3xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-3xl-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-3xl-6 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-3xl-7 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-3xl-8 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-3xl-9 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-3xl-10 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .py-3xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-3xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-3xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-3xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-3xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-3xl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-3xl-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-3xl-7 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-3xl-8 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-3xl-9 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-3xl-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .pt-3xl-0 {
    padding-top: 0 !important;
  }

  .pt-3xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-3xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-3xl-3 {
    padding-top: 1rem !important;
  }

  .pt-3xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-3xl-5 {
    padding-top: 2rem !important;
  }

  .pt-3xl-6 {
    padding-top: 3rem !important;
  }

  .pt-3xl-7 {
    padding-top: 4.5rem !important;
  }

  .pt-3xl-8 {
    padding-top: 6rem !important;
  }

  .pt-3xl-9 {
    padding-top: 8rem !important;
  }

  .pt-3xl-10 {
    padding-top: 10rem !important;
  }

  .pe-3xl-0 {
    padding-right: 0 !important;
  }

  .pe-3xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-3xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-3xl-3 {
    padding-right: 1rem !important;
  }

  .pe-3xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-3xl-5 {
    padding-right: 2rem !important;
  }

  .pe-3xl-6 {
    padding-right: 3rem !important;
  }

  .pe-3xl-7 {
    padding-right: 4.5rem !important;
  }

  .pe-3xl-8 {
    padding-right: 6rem !important;
  }

  .pe-3xl-9 {
    padding-right: 8rem !important;
  }

  .pe-3xl-10 {
    padding-right: 10rem !important;
  }

  .pb-3xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-3xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-3xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-3xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-3xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-3xl-5 {
    padding-bottom: 2rem !important;
  }

  .pb-3xl-6 {
    padding-bottom: 3rem !important;
  }

  .pb-3xl-7 {
    padding-bottom: 4.5rem !important;
  }

  .pb-3xl-8 {
    padding-bottom: 6rem !important;
  }

  .pb-3xl-9 {
    padding-bottom: 8rem !important;
  }

  .pb-3xl-10 {
    padding-bottom: 10rem !important;
  }

  .ps-3xl-0 {
    padding-left: 0 !important;
  }

  .ps-3xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-3xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-3xl-3 {
    padding-left: 1rem !important;
  }

  .ps-3xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-3xl-5 {
    padding-left: 2rem !important;
  }

  .ps-3xl-6 {
    padding-left: 3rem !important;
  }

  .ps-3xl-7 {
    padding-left: 4.5rem !important;
  }

  .ps-3xl-8 {
    padding-left: 6rem !important;
  }

  .ps-3xl-9 {
    padding-left: 8rem !important;
  }

  .ps-3xl-10 {
    padding-left: 10rem !important;
  }

  .text-3xl-start {
    text-align: left !important;
  }

  .text-3xl-end {
    text-align: right !important;
  }

  .text-3xl-center {
    text-align: center !important;
  }

  .text-max-width-3xl-0 {
    max-width: 0ch !important;
  }

  .text-max-width-3xl-10 {
    max-width: 10ch !important;
  }

  .text-max-width-3xl-20 {
    max-width: 20ch !important;
  }

  .text-max-width-3xl-30 {
    max-width: 30ch !important;
  }

  .text-max-width-3xl-40 {
    max-width: 40ch !important;
  }

  .text-max-width-3xl-50 {
    max-width: 50ch !important;
  }

  .text-max-width-3xl-60 {
    max-width: 60ch !important;
  }

  .text-max-width-3xl-70 {
    max-width: 70ch !important;
  }

  .text-max-width-3xl-80 {
    max-width: 80ch !important;
  }

  .text-max-width-3xl-90 {
    max-width: 90ch !important;
  }
}
@media (min-width: 2000px) {
  .float-4xl-start {
    float: left !important;
  }

  .float-4xl-end {
    float: right !important;
  }

  .float-4xl-none {
    float: none !important;
  }

  .d-4xl-inline {
    display: inline !important;
  }

  .d-4xl-inline-block {
    display: inline-block !important;
  }

  .d-4xl-block {
    display: block !important;
  }

  .d-4xl-grid {
    display: grid !important;
  }

  .d-4xl-table {
    display: table !important;
  }

  .d-4xl-table-row {
    display: table-row !important;
  }

  .d-4xl-table-cell {
    display: table-cell !important;
  }

  .d-4xl-flex {
    display: flex !important;
  }

  .d-4xl-inline-flex {
    display: inline-flex !important;
  }

  .d-4xl-none {
    display: none !important;
  }

  .flex-4xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-4xl-row {
    flex-direction: row !important;
  }

  .flex-4xl-column {
    flex-direction: column !important;
  }

  .flex-4xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-4xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-4xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-4xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-4xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-4xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-4xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-4xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-4xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-4xl-0 {
    gap: 0 !important;
  }

  .gap-4xl-1 {
    gap: 0.25rem !important;
  }

  .gap-4xl-2 {
    gap: 0.5rem !important;
  }

  .gap-4xl-3 {
    gap: 1rem !important;
  }

  .gap-4xl-4 {
    gap: 1.5rem !important;
  }

  .gap-4xl-5 {
    gap: 2rem !important;
  }

  .gap-4xl-6 {
    gap: 3rem !important;
  }

  .gap-4xl-7 {
    gap: 4.5rem !important;
  }

  .gap-4xl-8 {
    gap: 6rem !important;
  }

  .gap-4xl-9 {
    gap: 8rem !important;
  }

  .gap-4xl-10 {
    gap: 10rem !important;
  }

  .justify-content-4xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-4xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-4xl-center {
    justify-content: center !important;
  }

  .justify-content-4xl-between {
    justify-content: space-between !important;
  }

  .justify-content-4xl-around {
    justify-content: space-around !important;
  }

  .justify-content-4xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-4xl-start {
    align-items: flex-start !important;
  }

  .align-items-4xl-end {
    align-items: flex-end !important;
  }

  .align-items-4xl-center {
    align-items: center !important;
  }

  .align-items-4xl-baseline {
    align-items: baseline !important;
  }

  .align-items-4xl-stretch {
    align-items: stretch !important;
  }

  .align-content-4xl-start {
    align-content: flex-start !important;
  }

  .align-content-4xl-end {
    align-content: flex-end !important;
  }

  .align-content-4xl-center {
    align-content: center !important;
  }

  .align-content-4xl-between {
    align-content: space-between !important;
  }

  .align-content-4xl-around {
    align-content: space-around !important;
  }

  .align-content-4xl-stretch {
    align-content: stretch !important;
  }

  .align-self-4xl-auto {
    align-self: auto !important;
  }

  .align-self-4xl-start {
    align-self: flex-start !important;
  }

  .align-self-4xl-end {
    align-self: flex-end !important;
  }

  .align-self-4xl-center {
    align-self: center !important;
  }

  .align-self-4xl-baseline {
    align-self: baseline !important;
  }

  .align-self-4xl-stretch {
    align-self: stretch !important;
  }

  .order-4xl-first {
    order: -1 !important;
  }

  .order-4xl-0 {
    order: 0 !important;
  }

  .order-4xl-1 {
    order: 1 !important;
  }

  .order-4xl-2 {
    order: 2 !important;
  }

  .order-4xl-3 {
    order: 3 !important;
  }

  .order-4xl-4 {
    order: 4 !important;
  }

  .order-4xl-5 {
    order: 5 !important;
  }

  .order-4xl-last {
    order: 6 !important;
  }

  .m-4xl-0 {
    margin: 0 !important;
  }

  .m-4xl-1 {
    margin: 0.25rem !important;
  }

  .m-4xl-2 {
    margin: 0.5rem !important;
  }

  .m-4xl-3 {
    margin: 1rem !important;
  }

  .m-4xl-4 {
    margin: 1.5rem !important;
  }

  .m-4xl-5 {
    margin: 2rem !important;
  }

  .m-4xl-6 {
    margin: 3rem !important;
  }

  .m-4xl-7 {
    margin: 4.5rem !important;
  }

  .m-4xl-8 {
    margin: 6rem !important;
  }

  .m-4xl-9 {
    margin: 8rem !important;
  }

  .m-4xl-10 {
    margin: 10rem !important;
  }

  .m-4xl-auto {
    margin: auto !important;
  }

  .mx-4xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-4xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-4xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-4xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-4xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-4xl-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-4xl-6 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-4xl-7 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-4xl-8 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-4xl-9 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-4xl-10 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-4xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-4xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-4xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-4xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-4xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-4xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-4xl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-4xl-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-4xl-7 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-4xl-8 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-4xl-9 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-4xl-10 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-4xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-4xl-0 {
    margin-top: 0 !important;
  }

  .mt-4xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-4xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-4xl-3 {
    margin-top: 1rem !important;
  }

  .mt-4xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-4xl-5 {
    margin-top: 2rem !important;
  }

  .mt-4xl-6 {
    margin-top: 3rem !important;
  }

  .mt-4xl-7 {
    margin-top: 4.5rem !important;
  }

  .mt-4xl-8 {
    margin-top: 6rem !important;
  }

  .mt-4xl-9 {
    margin-top: 8rem !important;
  }

  .mt-4xl-10 {
    margin-top: 10rem !important;
  }

  .mt-4xl-auto {
    margin-top: auto !important;
  }

  .me-4xl-0 {
    margin-right: 0 !important;
  }

  .me-4xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-4xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-4xl-3 {
    margin-right: 1rem !important;
  }

  .me-4xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-4xl-5 {
    margin-right: 2rem !important;
  }

  .me-4xl-6 {
    margin-right: 3rem !important;
  }

  .me-4xl-7 {
    margin-right: 4.5rem !important;
  }

  .me-4xl-8 {
    margin-right: 6rem !important;
  }

  .me-4xl-9 {
    margin-right: 8rem !important;
  }

  .me-4xl-10 {
    margin-right: 10rem !important;
  }

  .me-4xl-auto {
    margin-right: auto !important;
  }

  .mb-4xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-4xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-4xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-4xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-4xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-4xl-5 {
    margin-bottom: 2rem !important;
  }

  .mb-4xl-6 {
    margin-bottom: 3rem !important;
  }

  .mb-4xl-7 {
    margin-bottom: 4.5rem !important;
  }

  .mb-4xl-8 {
    margin-bottom: 6rem !important;
  }

  .mb-4xl-9 {
    margin-bottom: 8rem !important;
  }

  .mb-4xl-10 {
    margin-bottom: 10rem !important;
  }

  .mb-4xl-auto {
    margin-bottom: auto !important;
  }

  .ms-4xl-0 {
    margin-left: 0 !important;
  }

  .ms-4xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-4xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-4xl-3 {
    margin-left: 1rem !important;
  }

  .ms-4xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-4xl-5 {
    margin-left: 2rem !important;
  }

  .ms-4xl-6 {
    margin-left: 3rem !important;
  }

  .ms-4xl-7 {
    margin-left: 4.5rem !important;
  }

  .ms-4xl-8 {
    margin-left: 6rem !important;
  }

  .ms-4xl-9 {
    margin-left: 8rem !important;
  }

  .ms-4xl-10 {
    margin-left: 10rem !important;
  }

  .ms-4xl-auto {
    margin-left: auto !important;
  }

  .m-4xl-n1 {
    margin: -0.25rem !important;
  }

  .m-4xl-n2 {
    margin: -0.5rem !important;
  }

  .m-4xl-n3 {
    margin: -1rem !important;
  }

  .m-4xl-n4 {
    margin: -1.5rem !important;
  }

  .m-4xl-n5 {
    margin: -2rem !important;
  }

  .m-4xl-n6 {
    margin: -3rem !important;
  }

  .m-4xl-n7 {
    margin: -4.5rem !important;
  }

  .m-4xl-n8 {
    margin: -6rem !important;
  }

  .m-4xl-n9 {
    margin: -8rem !important;
  }

  .m-4xl-n10 {
    margin: -10rem !important;
  }

  .mx-4xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-4xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-4xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-4xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-4xl-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-4xl-n6 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-4xl-n7 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .mx-4xl-n8 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-4xl-n9 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .mx-4xl-n10 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .my-4xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-4xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-4xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-4xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-4xl-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-4xl-n6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-4xl-n7 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-4xl-n8 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-4xl-n9 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-4xl-n10 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .mt-4xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-4xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-4xl-n3 {
    margin-top: -1rem !important;
  }

  .mt-4xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-4xl-n5 {
    margin-top: -2rem !important;
  }

  .mt-4xl-n6 {
    margin-top: -3rem !important;
  }

  .mt-4xl-n7 {
    margin-top: -4.5rem !important;
  }

  .mt-4xl-n8 {
    margin-top: -6rem !important;
  }

  .mt-4xl-n9 {
    margin-top: -8rem !important;
  }

  .mt-4xl-n10 {
    margin-top: -10rem !important;
  }

  .me-4xl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-4xl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-4xl-n3 {
    margin-right: -1rem !important;
  }

  .me-4xl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-4xl-n5 {
    margin-right: -2rem !important;
  }

  .me-4xl-n6 {
    margin-right: -3rem !important;
  }

  .me-4xl-n7 {
    margin-right: -4.5rem !important;
  }

  .me-4xl-n8 {
    margin-right: -6rem !important;
  }

  .me-4xl-n9 {
    margin-right: -8rem !important;
  }

  .me-4xl-n10 {
    margin-right: -10rem !important;
  }

  .mb-4xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-4xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-4xl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-4xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-4xl-n5 {
    margin-bottom: -2rem !important;
  }

  .mb-4xl-n6 {
    margin-bottom: -3rem !important;
  }

  .mb-4xl-n7 {
    margin-bottom: -4.5rem !important;
  }

  .mb-4xl-n8 {
    margin-bottom: -6rem !important;
  }

  .mb-4xl-n9 {
    margin-bottom: -8rem !important;
  }

  .mb-4xl-n10 {
    margin-bottom: -10rem !important;
  }

  .ms-4xl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-4xl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-4xl-n3 {
    margin-left: -1rem !important;
  }

  .ms-4xl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-4xl-n5 {
    margin-left: -2rem !important;
  }

  .ms-4xl-n6 {
    margin-left: -3rem !important;
  }

  .ms-4xl-n7 {
    margin-left: -4.5rem !important;
  }

  .ms-4xl-n8 {
    margin-left: -6rem !important;
  }

  .ms-4xl-n9 {
    margin-left: -8rem !important;
  }

  .ms-4xl-n10 {
    margin-left: -10rem !important;
  }

  .p-4xl-0 {
    padding: 0 !important;
  }

  .p-4xl-1 {
    padding: 0.25rem !important;
  }

  .p-4xl-2 {
    padding: 0.5rem !important;
  }

  .p-4xl-3 {
    padding: 1rem !important;
  }

  .p-4xl-4 {
    padding: 1.5rem !important;
  }

  .p-4xl-5 {
    padding: 2rem !important;
  }

  .p-4xl-6 {
    padding: 3rem !important;
  }

  .p-4xl-7 {
    padding: 4.5rem !important;
  }

  .p-4xl-8 {
    padding: 6rem !important;
  }

  .p-4xl-9 {
    padding: 8rem !important;
  }

  .p-4xl-10 {
    padding: 10rem !important;
  }

  .px-4xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-4xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-4xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-4xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-4xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-4xl-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-4xl-6 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-4xl-7 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-4xl-8 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-4xl-9 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-4xl-10 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .py-4xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-4xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-4xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-4xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-4xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-4xl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-4xl-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-4xl-7 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-4xl-8 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-4xl-9 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-4xl-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .pt-4xl-0 {
    padding-top: 0 !important;
  }

  .pt-4xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-4xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-4xl-3 {
    padding-top: 1rem !important;
  }

  .pt-4xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-4xl-5 {
    padding-top: 2rem !important;
  }

  .pt-4xl-6 {
    padding-top: 3rem !important;
  }

  .pt-4xl-7 {
    padding-top: 4.5rem !important;
  }

  .pt-4xl-8 {
    padding-top: 6rem !important;
  }

  .pt-4xl-9 {
    padding-top: 8rem !important;
  }

  .pt-4xl-10 {
    padding-top: 10rem !important;
  }

  .pe-4xl-0 {
    padding-right: 0 !important;
  }

  .pe-4xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-4xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-4xl-3 {
    padding-right: 1rem !important;
  }

  .pe-4xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-4xl-5 {
    padding-right: 2rem !important;
  }

  .pe-4xl-6 {
    padding-right: 3rem !important;
  }

  .pe-4xl-7 {
    padding-right: 4.5rem !important;
  }

  .pe-4xl-8 {
    padding-right: 6rem !important;
  }

  .pe-4xl-9 {
    padding-right: 8rem !important;
  }

  .pe-4xl-10 {
    padding-right: 10rem !important;
  }

  .pb-4xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-4xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-4xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-4xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-4xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-4xl-5 {
    padding-bottom: 2rem !important;
  }

  .pb-4xl-6 {
    padding-bottom: 3rem !important;
  }

  .pb-4xl-7 {
    padding-bottom: 4.5rem !important;
  }

  .pb-4xl-8 {
    padding-bottom: 6rem !important;
  }

  .pb-4xl-9 {
    padding-bottom: 8rem !important;
  }

  .pb-4xl-10 {
    padding-bottom: 10rem !important;
  }

  .ps-4xl-0 {
    padding-left: 0 !important;
  }

  .ps-4xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-4xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-4xl-3 {
    padding-left: 1rem !important;
  }

  .ps-4xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-4xl-5 {
    padding-left: 2rem !important;
  }

  .ps-4xl-6 {
    padding-left: 3rem !important;
  }

  .ps-4xl-7 {
    padding-left: 4.5rem !important;
  }

  .ps-4xl-8 {
    padding-left: 6rem !important;
  }

  .ps-4xl-9 {
    padding-left: 8rem !important;
  }

  .ps-4xl-10 {
    padding-left: 10rem !important;
  }

  .text-4xl-start {
    text-align: left !important;
  }

  .text-4xl-end {
    text-align: right !important;
  }

  .text-4xl-center {
    text-align: center !important;
  }

  .text-max-width-4xl-0 {
    max-width: 0ch !important;
  }

  .text-max-width-4xl-10 {
    max-width: 10ch !important;
  }

  .text-max-width-4xl-20 {
    max-width: 20ch !important;
  }

  .text-max-width-4xl-30 {
    max-width: 30ch !important;
  }

  .text-max-width-4xl-40 {
    max-width: 40ch !important;
  }

  .text-max-width-4xl-50 {
    max-width: 50ch !important;
  }

  .text-max-width-4xl-60 {
    max-width: 60ch !important;
  }

  .text-max-width-4xl-70 {
    max-width: 70ch !important;
  }

  .text-max-width-4xl-80 {
    max-width: 80ch !important;
  }

  .text-max-width-4xl-90 {
    max-width: 90ch !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.25rem !important;
  }

  .fs-2 {
    font-size: 1.85rem !important;
  }

  .fs-3 {
    font-size: 1.6rem !important;
  }

  .fs-4 {
    font-size: 1.35rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}